<template>
  <div>
    <b-container class="px-0">
      <b-row class="tab-card-fixed-height">
        <b-col
          lg="6"
          class="mb-1"
        >
          <b-card
            class="mb-0"
          >
            <table class="w-100">
              <tr>
                <th class="pb-50 va-baseline">
                  <span class="font-weight-bold">Po Start Date :</span>
                </th>
                <td class="pb-50 va-baseline">
                  {{ this.revenueData.po_start_date }}
                </td>
              </tr>
              <tr>
                <th class="pb-50 va-baseline">
                  <span class="font-weight-bold">Po End Date :</span>
                </th>
                <td class="pb-50 va-baseline">
                  {{ this.revenueData.po_end_date }}
                </td>
              </tr>
              <tr>
                <th class="pb-50 va-baseline">
                  <span class="font-weight-bold">Po Amount :</span>
                </th>
                <td class="pb-50 va-baseline">
                  {{ this.revenueData.po_amount }}
                </td>
              </tr>
              <tr>
                <th class="pb-50 va-baseline">
                  <span class="font-weight-bold">Payment Term :</span>
                </th>
                <td
                  v-if="this.revenueData.term == 1"
                  class="pb-50 va-baseline">
                  Yearly
                </td>
                <td
                  v-else-if="this.revenueData.term == 2"
                  class="pb-50 va-baseline">
                  Half Yearly
                </td>
                <td
                  v-else-if="this.revenueData.term == 4"
                  class="pb-50 va-baseline">
                  Quarterly
                </td>
                <td
                  v-else-if="this.revenueData.term == 3"
                  class="pb-50 va-baseline">
                  Monthly
                </td>
              </tr>
              <tr>
                <th class="pb-50 va-baseline">
                  <span class="font-weight-bold">Payment Amount :</span>
                </th>
                <td class="pb-50 va-baseline">
                  {{ this.revenueData.amount }}
                </td>
              </tr>
            </table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BContainer,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BContainer,
  },
  props: {
    revenueData: {},
  },
}
</script>

<style>

</style>
