import constants from './constants'

export default Object.freeze({
  COMPANY_CREATE_ACTIVITY: `${constants.COMPANY_API_PREFIX}/activity/add-activity`,
  COMPANY_UPDATE_ACTIVITY: `${constants.COMPANY_API_PREFIX}/activity/update-activity`,
  COMPANY_UPCOMING_ACTIVITY_LIST: `${constants.COMPANY_API_PREFIX}/activity/upcoming-list-activity`,
  COMPANY_ALL_ACTIVITY_LIST: `${constants.COMPANY_API_PREFIX}/activity/all-list-activity`,

  // Roles
  COMPANY_ROLES_LIST: `${constants.COMPANY_API_PREFIX}/user-role/list`,
  COMPANY_ROLE_BY_ID: `${constants.COMPANY_API_PREFIX}/user-role/get-by-id`,
  COMPANY_ROLE_SAVE: `${constants.COMPANY_API_PREFIX}/user-role/save`,
  COMPANY_ROLE_CHANGE_STATUS: `${constants.COMPANY_API_PREFIX}/user-role/change-status`,

  // Master Services
  MASTER_GET_SERVICE_LIST: `${constants.WEB_API_PREFIX}/admin/service/get-service-list`,
  MASTER_ADD_SERVICE_LIST: `${constants.WEB_API_PREFIX}/admin/service/add-new`,
  MASTER_GET_SERVICE_BY_ID: `${constants.WEB_API_PREFIX}/admin/service/get-by-id`,
  MASTER_SERVICE_CHANGE_STATUS: `${constants.WEB_API_PREFIX}/admin/service/change-status`,

  // Vendor
  IMPORT_VENDORS: `${constants.COMPANY_API_PREFIX}/vendor/import-vendors`,

})
