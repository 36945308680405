var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"changeStatusModal","title":_vm.remarkmodaltitle + ' (' + _vm.modalTitleExt + ')',"size":"lg","hide-footer":""}},[_c('b-card',[_c('validation-observer',{ref:"changeStatusModal"},[_c('b-form',{attrs:{"enctype":"multipart/form-data","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.addRemarkSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Task'),"label-for":"vi-task-title"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"Subject","vid":"vi-task-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0 w-100",class:errors[0] ? 'border-red-vselect' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"para_value","placeholder":_vm.t('Create a Task'),"reduce":function (sub) { return sub.para_id; },"options":_vm.subject},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Contact Person'),"label-for":"vi-contact-person"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"Contact Person","vid":"vi-contact-person","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0 w-100",class:errors[0] ? 'border-red-vselect' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","multiple":"","placeholder":_vm.t('Select Contact Person'),"reduce":function (ct) { return ct.id; },"options":_vm.allContactList},model:{value:(_vm.form.contact),callback:function ($$v) {_vm.$set(_vm.form, "contact", $$v)},expression:"form.contact"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Date Time'),"label-for":"vi-datetime"}},[_c('flat-pickr',{staticClass:"form-control",class:_vm.date_error ? 'border-red' : '',attrs:{"placeholder":_vm.t('Select Date Time'),"config":{ dateFormat: 'Y-m-d h:i K', enableTime: true }},on:{"input":_vm.changeDateTime},model:{value:(_vm.form.start_date_time),callback:function ($$v) {_vm.$set(_vm.form, "start_date_time", $$v)},expression:"form.start_date_time"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Purpose'),"label-for":"vi-purpose"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"Purpose","vid":"vi-purpose","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-purpose","autocomplete":"off","placeholder":_vm.t('Purpose')},model:{value:(_vm.form.purpose),callback:function ($$v) {_vm.$set(_vm.form, "purpose", $$v)},expression:"form.purpose"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Discussion'),"label-for":"vi-discussion"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"Discussion","vid":"vi-discussion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-discussion","autocomplete":"off","placeholder":_vm.t('Discussion')},model:{value:(_vm.form.discussion),callback:function ($$v) {_vm.$set(_vm.form, "discussion", $$v)},expression:"form.discussion"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('Outcome'),"label-for":"vi-outcome"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"Outcome","vid":"vi-outcome","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-outcome","autocomplete":"off","placeholder":_vm.t('Outcome')},model:{value:(_vm.form.outcome),callback:function ($$v) {_vm.$set(_vm.form, "outcome", $$v)},expression:"form.outcome"}})]}}])})],1)],1),( (_vm.loseReason) )?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Select Reson'),"label-for":"vi-task-title"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"reason","vid":"vi-reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0 w-100",class:errors[0] ? 'border-red-vselect' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"para_value","placeholder":_vm.t('Select Reason'),"reduce":function (sub) { return sub.para_id; },"options":_vm.reasons},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}})]}}],null,false,1073813436)})],1)],1):_vm._e(),( (_vm.loseReason) )?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Work awarded to which WMA? (other than NEPRA)'),"label-for":"vi-outcome"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"wma","vid":"vi-wma","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-wma","autocomplete":"off","placeholder":_vm.t('Enter WMA Name')},model:{value:(_vm.form.wma),callback:function ($$v) {_vm.$set(_vm.form, "wma", $$v)},expression:"form.wma"}})]}}],null,false,2248329206)})],1)],1):_vm._e(),(_vm.Revenue_Onboarded)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Applicability of PWM Rules'),"label-for":"vi-task-title"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"Subject","vid":"vi-task-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0 w-100",class:errors[0] ? 'border-red-vselect' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","placeholder":_vm.t('Select'),"multiple":true,"reduce":function (sub) { return sub.id; },"options":_vm.PWM_Rules},model:{value:(_vm.form.pwm_rules),callback:function ($$v) {_vm.$set(_vm.form, "pwm_rules", $$v)},expression:"form.pwm_rules"}})]}}],null,false,3299204918)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{class:_vm.attechmentReq ? 'required' : '',attrs:{"label":_vm.t('Upload Files'),"label-for":"vi-attachment"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"attachment","vid":"vi-attachment","rules":_vm.attechmentReq ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{class:errors[0] ? 'form-control border-red' : '',attrs:{"placeholder":_vm.t('Upload File'),"browse-text":_vm.t('Browse'),"multiple":""},model:{value:(_vm.form.attachment),callback:function ($$v) {_vm.$set(_vm.form, "attachment", $$v)},expression:"form.attachment"}})]}}])})],1)],1)],1),(_vm.Revenue_Onboarded)?[_c('fieldset',{staticClass:"fieldset-style"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('PO Start Date'),"label-for":"vi-start-date"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":_vm.t('Select PO Start Date'),"config":{ dateFormat: 'Y-m-d'}},model:{value:(_vm.form.po_start_date),callback:function ($$v) {_vm.$set(_vm.form, "po_start_date", $$v)},expression:"form.po_start_date"}})],1)],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('PO End Date'),"label-for":"vi-end-date"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":_vm.t('Select PO End Date'),"config":{ dateFormat: 'Y-m-d'}},model:{value:(_vm.form.po_end_date),callback:function ($$v) {_vm.$set(_vm.form, "po_end_date", $$v)},expression:"form.po_end_date"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('Po Amount'),"label-for":"vi-po_amount"}},[_c('validation-provider',{attrs:{"name":"PoAmount","vid":"vi-po_amount","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-po_amount","type":"text","maxlength":"15","autocomplete":"off","placeholder":_vm.t('Po Amount')},on:{"keypress":_vm.onlyNum},model:{value:(_vm.form.po_amount),callback:function ($$v) {_vm.$set(_vm.form, "po_amount", $$v)},expression:"form.po_amount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}],null,false,574856367)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Payment Term'),"label-for":"vi-type"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"Type","vid":"vi-type","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0 w-100",class:errors[0] ? 'border-red-vselect' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","placeholder":_vm.t('Select Type'),"reduce":function (ct) { return ct.id; },"options":_vm.typeList},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})]}}],null,false,951497309)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Payment Amount'),"label-for":"vi-amount"}},[_c('validation-provider',{attrs:{"name":"Amount","vid":"vi-amount","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-amount","type":"text","maxlength":"15","autocomplete":"off","placeholder":_vm.t('Amount')},on:{"keypress":_vm.onlyNum},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}],null,false,1943968400)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Rigid Qty(MT)'),"label-for":"vi-rigid_qty"}},[_c('validation-provider',{attrs:{"name":"RigidQty","vid":"vi-rigid_qty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-rigid_qty","type":"text","maxlength":"15","autocomplete":"off","placeholder":_vm.t('Rigid Qty')},on:{"keypress":_vm.onlyNum},model:{value:(_vm.form.rigid_qty),callback:function ($$v) {_vm.$set(_vm.form, "rigid_qty", $$v)},expression:"form.rigid_qty"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}],null,false,2409845009)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Rigid Rate(MT)'),"label-for":"vi-rigid_rate"}},[_c('validation-provider',{attrs:{"name":"RigidRate","vid":"vi-rigid_rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-rigid_rate","type":"text","maxlength":"15","autocomplete":"off","placeholder":_vm.t('Rigid Rate')},on:{"keypress":_vm.onlyNum},model:{value:(_vm.form.rigid_rate),callback:function ($$v) {_vm.$set(_vm.form, "rigid_rate", $$v)},expression:"form.rigid_rate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}],null,false,3102284303)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Flexible Qty(MT)'),"label-for":"vi-flexible_qty"}},[_c('validation-provider',{attrs:{"name":"FlexibleQty","vid":"vi-flexible_qty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-flexible_qty","type":"text","maxlength":"15","autocomplete":"off","placeholder":_vm.t('Flexible Qty')},on:{"keypress":_vm.onlyNum},model:{value:(_vm.form.flexible_qty),callback:function ($$v) {_vm.$set(_vm.form, "flexible_qty", $$v)},expression:"form.flexible_qty"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}],null,false,2157338677)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Flexible Rate(MT)'),"label-for":"vi-flexible_rate"}},[_c('validation-provider',{attrs:{"name":"FlexibleRate","vid":"vi-flexible_rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-flexible_rate","type":"text","maxlength":"15","autocomplete":"off","placeholder":_vm.t('Flexible Rate')},on:{"keypress":_vm.onlyNum},model:{value:(_vm.form.flexible_rate),callback:function ($$v) {_vm.$set(_vm.form, "flexible_rate", $$v)},expression:"form.flexible_rate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}],null,false,971966123)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('MLP Qty(MT)'),"label-for":"vi-MLP_qty"}},[_c('validation-provider',{attrs:{"name":"MLPQty","vid":"vi-MLP_qty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-MLP_qty","type":"text","maxlength":"15","autocomplete":"off","placeholder":_vm.t('MLP Qty')},on:{"keypress":_vm.onlyNum},model:{value:(_vm.form.MLP_qty),callback:function ($$v) {_vm.$set(_vm.form, "MLP_qty", $$v)},expression:"form.MLP_qty"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}],null,false,3295271697)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('MLP Rate(MT)'),"label-for":"vi-MLP_rate"}},[_c('validation-provider',{attrs:{"name":"MLPRate","vid":"vi-MLP_rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-MLP_rate","type":"text","maxlength":"15","autocomplete":"off","placeholder":_vm.t('MLP Rate')},on:{"keypress":_vm.onlyNum},model:{value:(_vm.form.MLP_rate),callback:function ($$v) {_vm.$set(_vm.form, "MLP_rate", $$v)},expression:"form.MLP_rate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}],null,false,4212214863)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Compostable Qty(MT)'),"label-for":"vi-compostable_qty"}},[_c('validation-provider',{attrs:{"name":"CompostableQty","vid":"vi-compostable_qty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-compostable_qty","type":"text","maxlength":"15","autocomplete":"off","placeholder":_vm.t('Compostable Qty')},on:{"keypress":_vm.onlyNum},model:{value:(_vm.form.compostable_qty),callback:function ($$v) {_vm.$set(_vm.form, "compostable_qty", $$v)},expression:"form.compostable_qty"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}],null,false,3396630067)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Compostable Rate(MT)'),"label-for":"vi-compostable_rate"}},[_c('validation-provider',{attrs:{"name":"CompostableRate","vid":"vi-compostable_rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-compostable_rate","type":"text","maxlength":"15","autocomplete":"off","placeholder":_vm.t('Compostable Rate')},on:{"keypress":_vm.onlyNum},model:{value:(_vm.form.compostable_rate),callback:function ($$v) {_vm.$set(_vm.form, "compostable_rate", $$v)},expression:"form.compostable_rate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}],null,false,2632390317)})],1)],1)],1)],1)]:_vm._e(),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.t('Submit'))+" ")]),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.remarkModalCancel}},[_vm._v(" "+_vm._s(_vm.t('Cancel'))+" ")])],1)],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }