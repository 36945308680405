<template>
  <b-modal
    id="addReminderModal"
    title="Add Reminder"
    size="md"
    hide-footer
  >
    <b-card>
      <validation-observer
        ref="addReminderForm"
      >
        <b-form
          enctype="multipart/form-data"
          autocomplete="off"
          @submit.prevent="addReminderSubmit"
        >
          <b-row>
            <b-col
              cols="12"
              class="p-0"
            >
              <b-form-group
                :label="t('Reminder')"
                label-for="vi-reminder"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Reminder"
                  vid="vi-Reminder"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-textarea
                      id="vi-reminder"
                      v-model="form.reminder"
                      :class="errors[0] ? 'border-red' : ''"
                      :browse-text="t('Browse')"
                      type="text"
                      :placeholder="t('Add Reminder')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
              >
                {{ t('Submit') }}
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="reminderModalCancel"
              >
                {{ t('Cancel') }}
              </b-button>
            </b-col>
          </b-row></b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import constants from '@/constants'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  // props: [''],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      form: {
        lead_id: this.$route.params.id,
        reminder: '',
        document: [],
      },
    }
  },
  watch: {
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (a, componentId) => {
      if (componentId === 'addReminderModal') {
        this.form = {
          reminder: '',
          document: [],
        }
      }
    })
  },
  methods: {
    addReminderSubmit() {
      // eslint-disable-next-line prefer-const
      let formData = new FormData()
      Object.entries(this.form).forEach(
        ([key, value]) => {
          formData.append(key, value)
        },
      )
      this.$refs.addReminderForm.validate().then(success => {
        if (success) {
          this.addReminder(formData)
        }
      })
    },
    addReminder(formData) {
      store.dispatch('vendorStore/addReminder', formData).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.$bvModal.hide('addReminderModal')
          this.$emit('loadAllReminders')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: '',
            },
          })
          this.$refs.updateContactData.reset()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: e.msg,
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
      })
    },
    reminderModalCancel() {
      this.$bvModal.hide('addReminderModal')
    },
  },
}
</script>

<style>

</style>
