<template>
  <b-modal
    id="closeLeadModal"
    :title="t('Close Lead')"
    hide-footer
    size="md"
  >
    <validation-observer ref="closeLead">
      <b-form
        autocomplete="off"
        @submit.prevent="submitCloseLead"
      >
        <b-form-group
          :label="t('Remark')"
          label-for="vi-remark"
          class="required"
        >
          <validation-provider
            #default="{ errors }"
            class="input-group my-1"
            name="Remark"
            vid="vi-remark"
            rules="required"
          >
            <b-form-textarea
              id="vi-remark"
              v-model="form.close_remark"
              autocomplete="off"
              :class="errors[0] ? 'border-red' : ''"
              :placeholder="t('Remark')"
            />
          </validation-provider>
        </b-form-group>
        <b-col
          cols="12"
          class="text-center pt-2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            {{ t('Submit') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="$bvModal.hide('closeLeadModal')"
          >
            {{ t('Cancel') }}
          </b-button>
        </b-col>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import constants from '@/constants'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: ['data_ids'],
  data() {
    return {
      closeAPI: '',
      form: {
        close_remark: '',
        activity_for_id: this.data_ids.activity_for_id ? this.data_ids.activity_for_id : '',
        activity_for: this.data_ids.activity_for ? this.data_ids.activity_for : '',
        lead_id: this.data_ids.lead_id ? this.data_ids.lead_id : '',
        vendor_id: this.data_ids.vendor_id ? this.data_ids.vendor_id : '',
        opportunity_id: this.data_ids.opportunity_id ? this.data_ids.opportunity_id : '',
        vendor_unit_id: this.data_ids.vendor_unit_id ? this.data_ids.vendor_unit_id : '',
      },
    }
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (a, componentId) => {
      if (componentId === 'closeLeadModal') {
        this.form = {
          close_remark: '',
          activity_for_id: this.data_ids.activity_for_id ? this.data_ids.activity_for_id : '',
          activity_for: this.data_ids.activity_for ? this.data_ids.activity_for : '',
          lead_id: this.data_ids.lead_id ? this.data_ids.lead_id : '',
          vendor_id: this.data_ids.vendor_id ? this.data_ids.vendor_id : '',
          opportunity_id: this.data_ids.opportunity_id ? this.data_ids.opportunity_id : '',
          vendor_unit_id: this.data_ids.vendor_unit_id ? this.data_ids.vendor_unit_id : '',
        }
      }
    })
    if (this.data_ids.opportunity_id) {
      this.closeAPI = 'vendorStore/closeOpportunity'
    } else {
      this.closeAPI = 'vendorStore/closeLead'
    }
  },
  methods: {
    async submitCloseLead() {
      await this.$refs.closeLead.validate().then(success => {
        this.form.lead_id = this.data_ids.lead_id
        if (success) {
          store.dispatch(this.closeAPI, this.form).then(response => {
            if (response.data.code === constants.SUCCESS) {
              this.$root.$emit('refreshLeadData', response)
              this.$bvModal.hide('closeLeadModal')
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: response.data.msg,
                  icon: 'Success',
                  variant: 'success',
                  text: '',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: response.data.msg,
                  icon: 'Danger',
                  variant: 'danger',
                  text: '',
                },
              })
            }
          }).catch(e => {
            this.$toast({
              component: ToastificationContentVue,
              position: 'top-right',
              props: {
                title: e.msg,
                icon: 'Danger',
                variant: 'danger',
                text: '',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
