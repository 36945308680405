<template>
  <b-modal
    id="editContactModal"
    :title="contactModalTitle"
    size="lg"
    hide-footer
  >
    <b-card>
      <validation-observer
        ref="updateContactData"
      >
        <b-form
          enctype="multipart/form-data"
          autocomplete="off"
          @submit.prevent="editContactSubmit"
        >
          <b-row>
            <!-- Name -->
            <b-col cols="6">
              <b-form-group
                :label="t('Name')"
                label-for="vi-Name"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="vi-Name"
                  rules="required|min:3"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      :id="'vi-Name'"
                      v-model="form.name"
                      :class="errors[0] ? 'border-red' : ''"
                      type="text"
                      autocomplete="off"
                      :placeholder="t('Name')"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- email -->
            <b-col cols="6">
              <b-form-group
                :label="t('Email')"
                label-for="vi-email"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="vi-email"
                  rules="required|email"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-email"
                      v-model="form.email"
                      :class="errors[0] ? 'border-red' : ''"
                      type="text"
                      autocomplete="off"
                      :placeholder="t('Email')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Mobile -->
            <b-col cols="6">
              <b-form-group
                :label="t('Mobile')"
                label-for="vi-mobile"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile"
                  vid="vi-mobile"
                  rules="min:10"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-mobile"
                      v-model="form.mobile"
                      :class="errors[0] ? 'border-red' : ''"
                      type="text"
                      maxlength="10"
                      autocomplete="off"
                      :placeholder="t('Mobile')"
                      @keypress="onlyNum"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- landline -->
            <b-col cols="4">
              <b-form-group
                :label="t('Landline')"
                label-for="vi-landline"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Landline"
                  vid="vi-landline"
                  rules="min:10"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-landline"
                      v-model="form.landline"
                      :class="errors[0] ? 'border-red' : ''"
                      type="text"
                      maxlength="15"
                      autocomplete="off"
                      :placeholder="t('Landline')"
                      @keypress="onlyNum"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ext -->
            <b-col cols="2">
              <b-form-group
                :label="t('Ext.')"
                label-for="vi-ext"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Ext"
                  vid="vi-ext"
                  rules="min:3"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-ext"
                      v-model="form.extension"
                      :class="errors[0] ? 'border-red' : ''"
                      type="text"
                      maxlength="10"
                      autocomplete="off"
                      :placeholder="t('Ext.')"
                      @keypress="onlyNum"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Select Department -->
            <b-col cols="6">
              <b-form-group
                :label="t('Department')"
                label-for="vi-department"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Department"
                  vid="vi-department"
                  rules=""
                >
                  <v-select
                    v-model="form.department_id"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    class="p-0 border-0"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="department_name"
                    placeholder=""
                    :reduce="d => d.id"
                    :options="deparments"
                  />
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Designation -->
            <b-col cols="6">
              <b-form-group
                :label="t('Designation')"
                label-for="vi-designation"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Designation"
                  vid="vi-designation"
                  rules="min:2"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-designation"
                      v-model="form.designation"
                      :class="errors[0] ? 'border-red' : ''"
                      type="text"
                      autocomplete="off"
                      maxlength="50"
                      :placeholder="t('Designation')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Select Services -->
            <b-col
              v-if="service_data"
              cols="6"
            >
              <b-form-group
                :label="t('Services')"
                label-for="vi-Services"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Services"
                  vid="vi-Services"
                  rules=""
                >
                  <v-select
                    v-model="form.assign_service"
                    class=" p-0 border-0"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="service_name"
                    :reduce="s => s.id"
                    multiple
                    placeholder=""
                    :options="service_data"
                  />
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Business Card -->
            <b-col
              cols="6"
              class="p-0"
            >
              <b-col cols="12">
                <b-form-group
                  :label="t('Business Card')"
                  label-for="vi-business-card"
                  class=""
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Business Card"
                    vid="vi-business-card"
                    rules=""
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-file
                        id="vi-business-card"
                        ref="business_Card"
                        v-model="form.file0"
                        :class="preview_message ? 'border-red-file' : ''"
                        :browse-text="t('Browse')"
                        name="business_Card"
                        type="file"
                        accept="image/*,.pdf,.doc,.docx"
                        :placeholder="t('Upload Business Card')"
                        @change="getprofile($event)"
                      />
                    </b-input-group>
                    <div
                      v-if="business_card_preview"
                      class="business_card_preview mt-1"
                    >
                      <!-- <img
                        :src="business_card_preview"
                        alt=""
                        width="100px"
                        height="auto"
                      > -->
                      <a
                        v-if="preview_message"
                        target="blank"
                        href="#"
                        class="text-danger"
                      > {{ file_error }}</a>
                      <a
                        v-else
                        target="blank"
                        :href="business_card_preview"
                      >{{ 'Preview' }}</a>
                    </div>
                    <small class="text-danger">{{ t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="text-center py-3"
            >
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
              >
                {{ t('Submit') }}
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="editModalCancel"
              >
                {{ t('Cancel') }}
              </b-button>
            </b-col>
          </b-row></b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { hideLoader, onlyNumber, showLoader } from '@/utils/common'
import store from '@/store'
import constants from '@/constants'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: ['editContactData', 'service_data', 'activity_ids'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      business_card_preview: '',
      services: [],
      editContact: false,
      addUserRequestAPI: '',
      updateUserRequestAPI: '',
      preview_message: false,
      file_error: '',
      contactModalTitle: 'Add Contact',
      form: {
        name: '',
        lead_id: 0,
        opportunity_id: 0,
        contact_id: '',
        email: '',
        mobile: '',
        landline: '',
        department_id: '',
        designation: '',
        assign_service: [],
        extension: '',
        file0: [],
        file_count: 0,
      },
    }
  },
  watch: {
    activity_ids(val) {
      this.form.lead_id = val.lead_id
      this.form.opportunity_id = val.opportunity_id
    },
    editContactData: {
      deep: true,
      handler(val) {
        this.editContact = true
        this.contactModalTitle = 'Edit Contact'
        this.form.assign_service = []
        this.form.contact_id = val.contact_id
        this.form.lead_id = val.lead_id ? val.lead_id : 0
        this.form.opportunity_id = val.opportunity_id ? val.opportunity_id : 0
        this.form.name = val.name
        this.form.email = val.email
        this.form.mobile = val.mobile
        this.form.landline = val.landline
        this.form.extension = val.extention
        this.form.department_id = val.department_id
        this.form.designation = val.designation
        if (val.service_data !== undefined && val.service_data.length > 0) {
          val.service_data.forEach(service => {
            this.form.assign_service.push(service.id)
          })
        }
        this.business_card_preview = val.business_card
      },
    },
  },
  mounted() {
    if (this.$route.name === 'lead/id') {
      this.form.lead_id = this.$route.params.id
    }
    // eslint-disable-next-line eqeqeq
    if (this.activity_ids.activity_for == constants.LEAD) {
      this.form.lead_id = this.activity_ids.lead_id
      this.addUserRequestAPI = 'vendorStore/createContactData'
      this.updateUserRequestAPI = 'vendorStore/updateContactData'
    // eslint-disable-next-line eqeqeq
    } else if (this.activity_ids.activity_for == constants.OPPORTNUITY) {
      this.form.lead_id = this.activity_ids.lead_id
      this.form.opportunity_id = this.activity_ids.opportunity_id
      this.addUserRequestAPI = 'vendorStore/createOpportunityContactData'
      this.updateUserRequestAPI = 'vendorStore/updateOpportunityContactData'
    }
    store.dispatch('ManageLeads/getDepartments')
      .then(response => {
        if (response.data.code === '200') {
          this.deparments = response.data.data
        }
      })
    this.$root.$on('bv::modal::hidden', (a, componentId) => {
      if (componentId === 'editContactModal') {
        this.contactModalTitle = 'Add Contact'
        this.business_card_preview = ''
        this.editContact = false
        this.form = {
          name: '',
          lead_id: this.activity_ids.lead_id,
          opportunity_id: this.activity_ids.opportunity_id,
          contact_id: '',
          email: '',
          mobile: '',
          landline: '',
          department_id: '',
          designation: '',
          assign_service: [],
          extension: '',
          file0: [],
          file_count: 0,
        }
      }
    })
  },
  methods: {
    onlyNum(a) {
      return onlyNumber(a)
    },
    editContactSubmit() {
      if (!this.preview_message) {
        // eslint-disable-next-line eqeqeq
        if (this.activity_ids.activity_for == constants.LEAD) {
          this.form.lead_id = this.activity_ids.lead_id
          // eslint-disable-next-line eqeqeq
        } else if (this.activity_ids.activity_for == constants.OPPORTNUITY) {
          this.form.lead_id = this.activity_ids.lead_id
          this.form.opportunity_id = this.activity_ids.opportunity_id
        }
        // eslint-disable-next-line prefer-const
        let formData = new FormData()
        Object.entries(this.form).forEach(
          ([key, value]) => {
            formData.append(key, value)
          },
        )
        this.$refs.updateContactData.validate().then(success => {
          if (success) {
            if (this.editContact) {
              this.updateContact(formData)
            } else {
              this.createContact(formData)
            }
          }
        })
      }
    },
    async updateContact(formData) {
      showLoader()
      await store.dispatch(this.updateUserRequestAPI, formData).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.$bvModal.hide('editContactModal')
          this.$emit('loadAllContact')

          this.editContact = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: '',
            },
          })
          this.$refs.updateContactData.reset()
          hideLoader()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          hideLoader()
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: e.msg,
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    async createContact(formData) {
      showLoader()
      await store.dispatch(this.addUserRequestAPI, formData).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.$bvModal.hide('editContactModal')
          this.$emit('loadAllContact')

          this.editContact = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: '',
            },
          })
          this.$refs.updateContactData.reset()
          this.form = {
            name: '',
            email: '',
            mobile: '',
            landline: '',
            department_id: '',
            designation: '',
            assign_service: [],
            extension: '',
            file0: [],
            file_count: 0,
          }
          hideLoader()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          hideLoader()
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: e.msg,
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    getprofile(event) {
      if (event.target.files.length > 0) {
        // eslint-disable-next-line eqeqeq
        if (event.target.files[0].type == 'application/x-zip-compressed') {
          this.preview_message = true
          this.file_error = 'Zip file not allowed'
        } else {
          this.preview_message = false
        }
        this.form.file_count = 1
        this.business_card_preview = URL.createObjectURL(event.target.files[0])
      }
    },
    editModalCancel() {
      this.$bvModal.hide('editContactModal')
    },
  },
}
</script>

<style>

</style>
