<template>
  <b-modal
    :id="viewContactModal"
    :title="''"
    size="lg"
    hide-footer
  >
    <b-card>

      <b-row>

        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="userData.business_card"
              :text="avatarText(userData.name)"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ userData.name }}
                </h4>
                <span class="card-text">{{ userData.email }}</span>
              </div>
              <div v-if="userData.business_card">
                <span class="card-text"> <a
                  :href="userData.business_card"
                  target="_blank"
                >{{ 'Business Card' }}</a></span>
              </div>
            </div>
          </div>

        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Mobile</span>
              </th>
              <td class="pb-50">
                {{ userData.mobile }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">LandLine</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.landline }}<span v-if="userData.extention">{{ `-${userData.extention}` }}</span>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Designation</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.designation }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
import 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { useUtils } from '@core/libs/i18n'

export default {
  components: {
  },
  props: ['userData', 'viewContactModal'],
  setup() {
    const { t } = useUtils()
    return {
      t,
      avatarText,
    }
  },
  data() {
    return {
      form: {
        lead_id: this.$route.params.id,
        reminder: '',
        document: [],
      },
    }
  },
  watch: {
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (a, componentId) => {
      if (componentId === 'addReminderModal') {
        this.form = {
          reminder: '',
          document: [],
        }
      }
    })
  },
  methods: {
    reminderModalCancel() {
      this.$bvModal.hide('addReminderModal')
    },
  },
}
</script>

<style>

</style>
