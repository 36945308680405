<template>
  <div
    id="contact_card"
    :class="itemView == 'grid-view' ? 'tab-card-fixed-height' : itemView == 'list-view' ? 'tab-card-fixed-height-noscroll' : ''"
  >
    <b-row
      v-if="!documentLists.length && itemView == 'grid-view'"
      class="mx-0"
    >
    <b-col
        v-if="!documentLists.length"
        xl="12"
        class="padding-b-28"
      >
        <b-card class="h-100">
          <b-row class="h-100 align-items-center">
            <b-col
              cols="12"
              class="pr-0 text-center"
            >
              {{ t('No Documents Found') }}
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
        class="grid-list-changer"
      >
        <b-form-radio-group
          v-model="itemView"
          class="position-absolute grid-list-button list item-view-radio-group right-0"
          buttons
          size="sm"
          button-variant="outline-primary"
        >
          <b-form-radio
            v-for="option in itemViewOptions"
            :key="option.value"
            :value="option.value"
          >
            <feather-icon
              :icon="option.icon"
              size="12"
            />
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>
    <b-row
      v-if="itemView == 'grid-view'"
      class="mt-25"
    >
      <b-col
        v-for="(remark, index) in documentLists"
        :key="index"
        md="2"
      >
        <a
          v-if="remark.document_url"
          :href="remark.document_url"
          target="_blank"
        >
          <div
            class="document-grid text-center"
          >
            <b-img
              v-if="images.includes(getFileExtension(remark.document_url))"
              :src="require('@/assets/images/icons/jpg.png')"
              height="auto"
              width="40"
            />
            <b-img
              v-else-if="pdf.includes(getFileExtension(remark.document_url))"
              :src="require('@/assets/images/icons/pdf.png')"
              height="auto"
              width="40"
            />
            <b-img
              v-else-if="docs.includes(getFileExtension(remark.document_url))"
              :src="require('@/assets/images/icons/doc.png')"
              height="auto"
              width="40"
            />
            <b-img
              v-else
              :src="require('@/assets/images/icons/unknown.png')"
              height="auto"
              width="40"
            />
            <div>{{ remark.document_name +'.'+ getFileExtension(remark.document_url) }}</div>
          </div>
        </a>
      </b-col>
    </b-row>
    <b-table
      v-if="itemView == 'list-view'"
      class="mt-25 contact-table-scroll h-100 pb-2"
      striped
      hover
      sticky-header
      responsive
      :items="document_table_items"
      :fields="document_table_fields"
    >
      <template #cell(document_url)="data">
        <a
          v-if="data.item.document_url"
          :href="data.item.document_url"
          target="_blank"
        >
          <img
            v-if="images.includes(getFileExtension(data.item.document_url))"
            :src="require('@/assets/images/icons/jpg.png')"
            alt=""
            width="30px"
          >
          <img
            v-else-if="pdf.includes(getFileExtension(data.item.document_url))"
            :src="require('@/assets/images/icons/pdf.png')"
            alt=""
            width="30px"
          >
          <img
            v-else-if="docs.includes(getFileExtension(data.item.document_url))"
            :src="require('@/assets/images/icons/doc.png')"
            alt=""
            width="30px"
          >
          <img
            v-else
            :src="require('@/assets/images/icons/doc.png')"
            alt=""
            width="30px"
          >
        </a>
        <span v-else> {{ }} </span>
      </template>
      <template #cell(created_at)="data">
        {{ dateformate(data.item.created_at) }}
      </template>
      <template
        #table-caption
      >
        <div
          v-if="!documentLists.length"
          class="text-center"
        >
          {{ t('No Documents Found') }}
        </div>
        <infinite-loading
          ref="infiniteDocumentLoading"
          :distance="10"
          @infinite="infiniteDocumentsHandler"
        >
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
      </template>
    </b-table>
    <b-col
      xl="12"
      class=""
    >
      <infinite-loading
        ref="infiniteDocumentLoading"
        :distance="10"
        @infinite="infiniteDocumentsHandler"
      >
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </b-col>
    <add-document
      :activity_ids="activity_ids"
      @loadAllDocuments="getDocuments"
    />

    <b-button
      v-if="is_edit || is_edit_opportunity"
      variant="primary"
      class="addPlusBtn"
      @click="addDocument"
    >
      <feather-icon icon="PlusIcon" />
    </b-button>
  </div>
</template>

<script>
// import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { useUtils } from '@core/libs/i18n'
import InfiniteLoading from 'vue-infinite-loading'
import moment from 'moment'
import $ from 'jquery'
import AddDocument from './AddDocument.vue'
import {
  hideLoader, showLoader, images, docs, pdf,
} from '@/utils/common'
import store from '@/store'
import constants from '@/constants'

export default {
  components: {
    AddDocument,
    // AppTimeline,
    // AppTimelineItem,
    InfiniteLoading,
  },
  props: [
    'activity_ids',
    'is_edit',
    'is_edit_opportunity',
  ],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      images,
      docs,
      pdf,
      documentLists: [],
      documentAPI: '',
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      document_table_items: [],
      document_table_fields: [],
      form: {
        size: 15,
        pageNumber: 1,
        activity_for_id: this.activity_ids.activity_for_id ? this.activity_ids.activity_for_id : '',
        activity_for: this.activity_ids.activity_for ? this.activity_ids.activity_for : '',
        lead_id: this.activity_ids.lead_id ? this.activity_ids.lead_id : '',
        vendor_id: this.activity_ids.vendor_id ? this.activity_ids.vendor_id : '',
        opportunity_id: this.activity_ids.opportunity_id ? this.activity_ids.opportunity_id : '',
        vendor_unit_id: this.activity_ids.vendor_unit_id ? this.activity_ids.vendor_unit_id : '',
      },
    }
  },
  watch: {
    itemView(val) {
      // eslint-disable-next-line eqeqeq
      if (val == 'list-view') {
        $(`input[value="${val}"]`).parents('.tab-card-fixed-height')[0].scrollTop = 0
      }
    },
  },
  created() {
    this.document_table_fields = ['document_url', 'document_name', 'created_by_name', 'created_at']
    this.$root.$on('loadAllDocuments', () => {
      this.getDocsAPI()
    })
  },
  mounted() {
    // eslint-disable-next-line eqeqeq
    if (this.activity_ids.activity_for == constants.OPPORTNUITY || this.activity_ids.activity_for == constants.VENDOR) {
      // this.documentAPI = 'vendorStore/getOpportunityDocuments'
      this.documentAPI = 'vendorStore/getDocuments'
    // eslint-disable-next-line eqeqeq
    } else if (this.activity_ids.activity_for == constants.LEAD) {
      this.documentAPI = 'vendorStore/getDocuments'
    }
    this.getDocsAPI()
  },
  methods: {
    dateformate(date) {
      return moment(date).format('DD-MM-YYYY H:mm')
    },
    infiniteDocumentsHandler($state) {
      store.dispatch(this.documentAPI, this.form).then(response => {
        if (response.data.data.data.length) {
          this.documentLists = response.data.data.data
          this.document_table_items = response.data.data.data
          this.form.pageNumber += 1
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    getDocuments() {
      showLoader()
      // await store.dispatch(this.documentAPI, this.form).then(response => {
      //   if (response.data.code === constants.SUCCESS) {
      //     this.documentLists = response.data.data.data
      //   }
      // })
      this.form.pageNumber = 1
      this.form.size = 15
      this.documentLists = []
      this.document_table_items = []
      this.$refs.infiniteDocumentLoading.stateChanger.reset()
      hideLoader()
    },
    getDocsAPI() {
      store.dispatch(this.documentAPI, this.form).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.form.pageNumber = 1
          this.form.size = 15
          this.documentLists = response.data.data.data
        }
      })
    },
    getFileExtension(file) {
      // eslint-disable-next-line prefer-const
      return file.split('.').pop()
    },
    getFileName(file) {
      // eslint-disable-next-line prefer-const
      return file.substring(file.lastIndexOf('/') + 1)
    },
    addDocument() {
      this.$bvModal.show('addDocumentModal')
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-card-fixed-height{
    height: 320px;
    overflow: auto;
    margin-bottom: 10px;
}
.document-grid {
  background: #7367f01a;
    padding: 12px;
    border-radius: 10px;
    margin-bottom: 10px;
    border: 1px solid #7367f01a;
}
.tab-card-fixed-height{
    // height: 320px;
    min-height: 320px;
    height: calc(100vh - 504px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 10px;
}
.tab-card-fixed-height-noscroll {
   min-height: 320px;
    height: calc(100vh - 504px);
    overflow: hidden;
    margin-bottom: 10px;
}
.tab-card-fixed-height::-webkit-scrollbar{
display: none;
}
.grid-list-changer{
  position: absolute;
    top: 0;
    width: 100%;
    height: 35px;
    z-index: 2;
    background: white;
}
.dark-layout .grid-list-changer {
  background: #283046;
}
.table-business-card{
  max-width: 25px !important;
}
.grid-list-button{
  top: 5px;
  right: 5px;
}
.mt-25 {
  margin-top: 25px !important;
}
</style>
