<template>
  <b-tabs>
    <b-tab
      :title="t('New Activity')"
    >
      <validation-observer
        ref="addActivity"
      >
        <b-form-group
          :label="t('Task')"
          label-for="vi-task-title"
          class="required"
        >
          <div class="d-flex">
            <validation-provider
              #default="{ errors }"
              class="input-group"
              name="Subject"
              vid="vi-task-name"
              rules="required"
            >
              <v-select
                v-model="form.subject"
                class="p-0 border-0 w-100"
                :class="errors[0] ? 'border-red-vselect' : ''"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="para_value"
                :placeholder="t('Create a Task')"
                :reduce="sub => sub.para_id"
                :options="subject"
              />
            </validation-provider>
            <b-button
              variant="primary ml-1"
              :disabled="addBtnDisable"
              @click="collapseAddTask"
            > {{ t('Add') }}</b-button>
          </div>
        </b-form-group>
      </validation-observer>

      <validation-observer ref="submitTask">
        <div class="mb-1">
          <b-collapse id="collapseAddTask">
            <b-row>
              <b-form-group
                :label="t('Contact Person')"
                label-for="vi-contact-person"
                class="col-md-6"
              >
                <validation-provider
                  #default="{ errors }"
                  class=""
                  name="Contact Person"
                  vid="vi-contact-person"
                  rules=""
                >
                  <v-select
                    v-model="form.contact"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    class="p-0 border-0"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    multiple
                    :placeholder="t('Select Contact Person')"
                    :reduce="ct => ct.id"
                    :options="allContactList"
                  />
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="t('Date Time')"
                label-for="vi-datetime"
                class="required col-md-6"
              >
                <!-- <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Date Time"
                  vid="vi-datetime"
                  rules="required"
                > -->
                <flat-pickr
                  v-if="addActivityDateTimeDisable"
                  v-model="form.start_date_time"
                  :class="date_error ? 'border-red' : ''"
                  :placeholder="t('Select Date Time')"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d h:i K', enableTime: true, disable: [{ from: '1970-08-20', to: new Date().setDate(new Date().getDate() - (date_limit + 1)) }] }"
                  @input="changeDateTime"
                />
                <b-form-input
                  v-if="!addActivityDateTimeDisable"
                  id="vi-datetime"
                  v-model="form.start_date_time"
                  autocomplete="off"
                  disabled
                  :class="errors[0] ? 'border-red' : ''"
                  :placeholder="t('Select Date Time')"
                />
                <!-- </validation-provider> -->
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                :label="t('Visitors (optional)')"
                label-for="vi-contact-person"
                class="col-md-12"
              >
                <validation-provider
                  #default="{ errors }"
                  class=""
                  name="Visitors"
                  vid="vi-contact-person"
                  rules=""
                >
                  <v-select
                    v-model="form.users"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    class="p-0 border-0"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="company_user_name"
                    multiple
                    :placeholder="t('Select User')"
                    :reduce="ct => ct.companyuserid"
                    :options="usersList"
                  />
                </validation-provider>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                :label="t('Purpose')"
                label-for="vi-purpose"
                class="required col-md-6"
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Purpose"
                  vid="vi-purpose"
                  rules="required"
                >
                  <b-form-textarea
                    id="vi-purpose"
                    v-model="form.purpose"
                    autocomplete="off"
                    :class="errors[0] ? 'border-red' : ''"
                    :placeholder="t('Purpose')"
                  />
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="t('Discussion')"
                label-for="vi-discussion"
                class="required col-md-6"
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Discussion"
                  vid="vi-discussion"
                  rules="required"
                >
                  <b-form-textarea
                    id="vi-discussion"
                    v-model="form.discussion"
                    autocomplete="off"
                    :class="errors[0] ? 'border-red' : ''"
                    :placeholder="t('Discussion')"
                  />
                </validation-provider>
              </b-form-group>
            </b-row>

            <b-form-group
              :label="t('Outcome')"
              label-for="vi-outcome"
              class=""
            >
              <validation-provider
                #default="{ errors }"
                class="input-group"
                name="Outcome"
                vid="vi-outcome"
                rules=""
              >
                <b-form-textarea
                  id="vi-outcome"
                  v-model="form.outcome"
                  autocomplete="off"
                  :class="errors[0] ? 'border-red' : ''"
                  :placeholder="t('Outcome')"
                />
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="t('Upload File')"
              label-for="vi-uploadFile"
            >
              <b-form-file
                id="vi-uploadFile"
                v-model="form.attachment"
                :placeholder="t('Upload File')"
                :browse-text="t('Browse')"
              />
            </b-form-group>

            <div class="d-flex justify-content-around">
              <b-button
                variant="primary"
                @click="submitTask"
              >
                {{ t('Submit') }}
              </b-button>
              <b-button
                variant="primary"
                @click="cancelTask"
              >
                {{ t('Cancel') }}
              </b-button>
            </div>
          </b-collapse>

        </div>

      </validation-observer>
    </b-tab>
  </b-tabs>
</template>

<script>
import vSelect from 'vue-select'
import { useUtils } from '@core/libs/i18n'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import { showLoader, hideLoader, encrypt } from '@/utils/common'
import store from '@/store'
import constants from '@/constants'
import apis from '@/apis'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    vSelect,
  },
  props: ['contacts', 'data_ids', 'editactivitydata', 'date_limit'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      required,
      date_error: false,
      addBtnDisable: false,
      addActivityDateTimeDisable: true,
      formCollapsed: true,
      getContactListDispatchPath: '',
      allContactList: [],
      form: {
        activity_for_id: this.data_ids.activity_for_id ? this.data_ids.activity_for_id : '',
        activity_for: this.data_ids.activity_for ? this.data_ids.activity_for : '',
        lead_id: this.data_ids.lead_id ? this.data_ids.lead_id : '',
        vendor_id: this.data_ids.vendor_id ? this.data_ids.vendor_id : '',
        opportunity_id: this.data_ids.opportunity_id ? this.data_ids.opportunity_id : '',
        vendor_unit_id: this.data_ids.vendor_unit_id ? this.data_ids.vendor_unit_id : '',
        unit_id: this.data_ids.unit_id ? this.data_ids.unit_id : '',
        activity_id: '',
        subject: '',
        purpose: '',
        discussion: '',
        outcome: '',
        start_date_time: '',
        contact: '',
        users: '',
        attachment: [],
      },
      subject: [],
      usersList: [],
    }
  },

  watch: {
    data_ids: {
      deep: true,
      handler(vals) {
        this.form.activity_for = vals.activity_for ? vals.activity_for : ''
        this.form.opportunity_id = vals.activity_for_id ? vals.activity_for_id : ''
        this.form.activity_for_id = vals.activity_for_id ? vals.activity_for_id : ''
        this.form.lead_id = vals.lead_id ? vals.lead_id : ''
        this.form.vendor_id = vals.vendor_id ? vals.vendor_id : ''
        this.form.unit_id = vals.unit_id ? vals.unit_id : ''
      },
    },
    editactivitydata: {
      deep: true,
      handler(editData) {
        this.form.activity_id = editData.activity_id
        this.form.subject = editData.subject
        this.form.purpose = editData.purpose
        this.form.discussion = editData.discussion
        this.form.outcome = editData.outcome
        this.form.start_date_time = editData.start_date_time.slice(0, -3)
        if (new Date(this.form.start_date_time) < new Date() && new Date(this.form.start_date_time).getMonth() + 1 <= new Date().getMonth() + 1) {
          this.addActivityDateTimeDisable = false
        } else {
          this.addActivityDateTimeDisable = true
        }
        this.form.contact = editData.contact
        // eslint-disable-next-line eqeqeq
        if (this.formCollapsed == true) {
          this.$root.$emit('bv::toggle::collapse', 'collapseAddTask')
        }
        this.addBtnDisable = true
        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
          // eslint-disable-next-line eqeqeq
          if (collapseId == 'collapseAddTask' && isJustShown == true) {
            this.formCollapsed = !isJustShown
          // eslint-disable-next-line eqeqeq
          } else if (collapseId == 'collapseAddTask' && isJustShown == false) {
            this.formCollapsed = !isJustShown
          }
        })
      },
    },
  },
  mounted() {
    store.dispatch('activityStore/getSubjectList').then(response => {
      this.subject = response.data.data
    })
    store.dispatch('activityStore/lostReasonList').then(response => {
      this.reasons = response.data.data
    })
    store.dispatch('CompanyuserManage/getAutoUsers').then(response => {
      if (response.data.code === '200') {
        this.usersList = response.data.data
      }
    })
    if (this.form.activity_for === constants.LEAD) {
      this.getContactListDispatchPath = 'ManageLeads/getLeadContactData'
    } else if (this.form.activity_for === constants.VENDOR) {
      this.getContactListDispatchPath = 'vendorStore/getVendorContactData'
    } else if (this.form.activity_for === constants.OPPORTNUITY) {
      this.getContactListDispatchPath = 'vendorStore/getOpportunityContactData'
    }
    this.getUsersList()
  },
  methods: {
    changeDateTime() {
      if (this.form.start_date_time) {
        this.date_error = false
      }
    },
    getUsersList() {
      store.dispatch(this.getContactListDispatchPath, this.form).then(response => {
        this.allContactList = response.data.data.data
      })
    },
    collapseAddTask() {
      this.$refs.addActivity.validate().then(success => {
        if (success) {
          this.addActivityDateTimeDisable = true
          this.addBtnDisable = true
          this.$root.$emit('bv::toggle::collapse', 'collapseAddTask')
        }
      })
    },
    submitTask() {
      // eslint-disable-next-line no-unreachable
      this.$refs.submitTask.validate().then(success => {
        if (success) {
          if (!this.form.start_date_time) {
            this.date_error = true
            return
          }
          // eslint-disable-next-line prefer-const
          let encformData = {}
          // eslint-disable-next-line prefer-const
          let formData = new FormData()
          Object.entries(this.form).forEach(
            // eslint-disable-next-line eqeqeq
            ([key, value]) => { if (key == 'attachment') { if (value == null || value.length == 0) { formData.append('', '') } else { formData.append(key, value) } } else { formData.append(key, value) } },
          )
          Object.entries(this.form).forEach(
            // eslint-disable-next-line eqeqeq
            ([key, value]) => {
              if (key !== 'attachment') { encformData[key] = value.toString() }
            },
          )
          if (this.form.activity_id) {
            this.updateActivity(apis.COMPANY_UPDATE_ACTIVITY, formData, encrypt(encformData))
          } else {
            // API(apis.CREATE_ACTIVITY, formData, encformData).then(res => {
            //   alert(JSON.stringify(res))
            // })
            this.saveActivity(apis.COMPANY_CREATE_ACTIVITY, formData, encrypt(encformData))
          }
        }
      })
    },
    async saveActivity(url, request, secretKey) {
      showLoader()
      await store.dispatch('app/API', { url, request, secretKey }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.$root.$emit('bv::toggle::collapse', 'collapseAddTask')
          this.form = {
            activity_for: this.data_ids.activity_for ? this.data_ids.activity_for : '',
            activity_for_id: this.data_ids.activity_for_id ? this.data_ids.activity_for_id : '',
            lead_id: this.data_ids.lead_id ? this.data_ids.lead_id : '',
            vendor_id: this.data_ids.vendor_id ? this.data_ids.vendor_id : '',
            unit_id: this.data_ids.unit_id ? this.data_ids.unit_id : '',
            opportunity_id: this.data_ids.activity_for_id ? this.data_ids.activity_for_id : '',
            activity_id: '',
            subject: '',
            purpose: '',
            discussion: '',
            outcome: '',
            start_date_time: '',
            contact: '',
            users: '',
            attachment: [],
          }
          this.$refs.addActivity.reset()
          this.$refs.submitTask.reset()
          this.addBtnDisable = false
          this.$root.$emit('refreshActivity')
          this.$root.$emit('loadAllDocuments')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Task Successfully Added',
              icon: 'Success',
              variant: 'success',
              text: '',
            },
          })
          hideLoader()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.message,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          hideLoader()
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: e.msg,
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
      // await store.dispatch('activityStore/createActivity', { formData, encKey }).then(response => {
      //   // eslint-disable-next-line eqeqeq
      //   if (response.data.code == 200) {
      //     this.$root.$emit('bv::toggle::collapse', 'collapseAddTask')
      //     this.form = {
      //       activity_for: this.data_ids.activity_for ? this.data_ids.activity_for : '',
      //       activity_for_id: this.data_ids.activity_for_id ? this.data_ids.activity_for_id : '',
      //       lead_id: this.data_ids.lead_id ? this.data_ids.lead_id : '',
      //       vendor_id: this.data_ids.vendor_id ? this.data_ids.vendor_id : '',
      //       unit_id: this.data_ids.unit_id ? this.data_ids.unit_id : '',
      //       opportunity_id: this.data_ids.activity_for_id ? this.data_ids.activity_for_id : '',
      //       activity_id: '',
      //       subject: '',
      //       purpose: '',
      //       discussion: '',
      //       outcome: '',
      //       start_date_time: '',
      //       contact: '',
      //       attachment: [],
      //     }
      //     this.$refs.addActivity.reset()
      //     this.$refs.submitTask.reset()
      //     this.addBtnDisable = false
      //     this.$root.$emit('refreshActivity')
      //     this.$toast({
      //       component: ToastificationContent,
      //       position: 'top-right',
      //       props: {
      //         title: 'Task Successfully Added',
      //         icon: 'Success',
      //         variant: 'success',
      //         text: '',
      //       },
      //     })
      //     hideLoader()
      //   } else {
      //     this.$toast({
      //       component: ToastificationContent,
      //       position: 'top-right',
      //       props: {
      //         title: response.data.message,
      //         icon: 'Danger',
      //         variant: 'danger',
      //         text: '',
      //       },
      //     })
      //     hideLoader()
      //   }
      // }).catch(e => {
      //   this.$toast({
      //     component: ToastificationContent,
      //     position: 'top-right',
      //     props: {
      //       title: e.msg,
      //       icon: 'Danger',
      //       variant: 'danger',
      //       text: '',
      //     },
      //   })
      //   hideLoader()
      // })
    },
    async updateActivity(url, request, secretKey) {
      showLoader()
      await store.dispatch('app/API', { url, request, secretKey }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == 200) {
          this.$root.$emit('bv::toggle::collapse', 'collapseAddTask')
          this.form = {
            activity_for: this.data_ids.activity_for ? this.data_ids.activity_for : '',
            activity_for_id: this.data_ids.activity_for_id ? this.data_ids.activity_for_id : '',
            lead_id: this.data_ids.lead_id ? this.data_ids.lead_id : '',
            vendor_id: this.data_ids.vendor_id ? this.data_ids.vendor_id : '',
            unit_id: this.data_ids.unit_id ? this.data_ids.unit_id : '',
            activity_id: '',
            subject: '',
            purpose: '',
            discussion: '',
            outcome: '',
            start_date_time: '',
            contact: '',
            attachment: [],
          }
          this.$refs.addActivity.reset()
          this.$refs.submitTask.reset()
          this.addBtnDisable = false
          this.$root.$emit('refreshActivity', '')
          this.$root.$emit('loadAllDocuments')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Task Successfully Updated',
              icon: 'Success',
              variant: 'success',
              text: '',
            },
          })
          hideLoader()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.message,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          hideLoader()
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something went wrong!',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    cancelTask() {
      this.form = {
        activity_for: this.data_ids.activity_for ? this.data_ids.activity_for : '',
        activity_for_id: this.data_ids.activity_for_id ? this.data_ids.activity_for_id : '',
        lead_id: this.data_ids.lead_id ? this.data_ids.lead_id : '',
        vendor_id: this.data_ids.vendor_id ? this.data_ids.vendor_id : '',
        unit_id: this.data_ids.unit_id ? this.data_ids.unit_id : '',
        activity_id: '',
        subject: '',
        purpose: '',
        discussion: '',
        outcome: '',
        start_date_time: '',
        contact: '',
        attachment: [],
      }
      this.$refs.addActivity.reset()
      this.$refs.submitTask.reset()
      this.addBtnDisable = false
      this.$root.$emit('bv::toggle::collapse', 'collapseAddTask')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
