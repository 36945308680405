<template>
  <div>
    <div
      v-for="(parent, PIndex) in repeaterArray"
      :key="parent.id"
      class="table-responsive mh-230 table-scrollbar"
    >
      <table
        class="table table-bordered table-hover table-striped"
      >
        <thead class="fixed-header">
          <tr>
            <th :colspan="parent.repeater[0].repeater_data.length + 1">
              {{ parent.question }}
            </th>
          </tr>
          <tr>
            <th
              v-for="(item) in parent.repeater[0].repeater_data"
              :key="item.id"
            >{{ item.question }}
            </th>
            <th>{{ t('Action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(el, index) in parent.repeater"
            :key="el.id"
          >
            <td
              v-for="(item, idx) in el.repeater_data"
              :key="item.id"
            >
              <b-form-input
                v-if="item.group_name == 'text' || item.group_name == 'Text'"
                :id="item.id.toString()"
                :class="el.repeater_data.length > 3 ? 'width-300' : '' "
                v-model="item.answer_text"
                :type="item.group_name"
              />
              <v-select
                v-if="item.group_name == 'select' || item.group_name == 'Select'"
                v-model="item.answer_id"
                :class="el.repeater_data.length > 3 ? 'width-300' : '' "
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Select"
                label="options"
                :reduce="op => op.id.toString()"
                :options="item.options"
              />
              <b-form-file
                v-if="item.group_name == 'file' || item.group_name == 'File'"
                v-model="item.answer_media"
                :class="el.repeater_data.length > 3 ? 'width-300' : '' "
                type="file"
                placeholder="Upload File"
                @input="uploadFile(PIndex, index, idx)"
              />
            </td>
            <td class="text-center">
              <b-button
                v-show="index > 0"
                type="button"
                variant="danger"
                @click="removeItem(PIndex, index)"
              > {{ t('-') }}
              </b-button>
            </td>
          </tr>
          <tr>
            <td :colspan="parent.repeater[0].repeater_data.length"></td>
            <td class="text-center">
              <b-button
                type="button"
                variant="primary"
                @click="addItem(PIndex)"
              > {{ t('Add') }}
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    repeaterArray: { type: Array, required: false, default: () => [] },
    ducumentFile: { type: Array, required: false, default: () => [] },
  },
  data() {
    return {
      repeater_data: [],
    }
  },
  setup() {
    const { t } = useUtils()
    return {
      t,
    }
  },
  mounted() {
  },
  methods: {
    addItem(index) {
      this.repeater_data = []
      this.repeaterArray[index].repeater[0].repeater_data.forEach(element => {
        this.repeater_data.push(
          {
            id: element.id + 1,
            template_id: element.template_id,
            opportunity_id: element.opportunity_id,
            company_id: element.company_id,
            parent_question_id: element.parent_question_id,
            question_id: element.question_id,
            answer_id: '',
            answer_text: '',
            other_text: '',
            media_id: '',
            question: element.question,
            group_id: element.group_id,
            group_name: element.group_name,
            from_master: element.from_master,
            master_table: element.master_table,
            is_multiple: element.is_multiple,
            options: element.options,
            answer_media: '',
            file_count: 0,
          },
        )
      })
      this.repeaterArray[index].repeater.push(
        {
          id: this.repeaterArray[index].repeater.length + 1,
          repeater_data: this.repeater_data,
        },
      )
    },
    removeItem(PIndex, index) {
      if (index > 0) {
        this.repeaterArray[PIndex].repeater.splice(index, 1)
      }
    },
    uploadFile(PIndex, index, idx) {
      this.ducumentFile.push(
        {
          id: this.repeaterArray[PIndex].repeater[index].id,
          question_id: this.repeaterArray[PIndex].repeater[index].repeater_data[idx].question_id,
          answer_media: this.repeaterArray[PIndex].repeater[index].repeater_data[idx].answer_media,
        },
      )
    },
  },
}
</script>

<style lang="scss">
.table-scrollbar {
  min-height: 200px;
}
::-webkit-scrollbar {
  width: 6px;
  max-height: 9px;
  height: 9px !important;
}
.width-300 {
  min-width: 300px;
}
.fixed-header {
  position: sticky;
  top: 0;
  z-index: 100;
}
</style>
