<template>
  <div>
    <b-row class="tab-card-fixed-height">
      <b-col
        v-if="!remarks.length && !close_remark"
        xl="12"
        class="padding-b-28"
      >
        <b-card class="h-100">
          <b-row class="h-100 align-items-center">
            <b-col
              cols="12"
              class="pr-0 text-center"
            >
              {{ t('No Remarks Found') }}
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <app-timeline class="p-1">
        <app-timeline-item
          v-if="close_remark"
          :title="close_remark"
          :class="close_remark ? '' : 'removeRemark'"
          variant="success"
        />
        <app-timeline-item
          v-for="(remark, index) in remarks"
          :key="index"
          :variant="'success'"
        >
          <span>
            {{ remark.remark }}
          </span>
          <div>
            <span v-if="remark.document_url">
              <a
                :href="remark.document_url"
                target="_blank"
              >
                <b-img
                  v-if="images.includes(getFileExtension(remark.document_url))"
                  :src="require('@/assets/images/icons/jpg.png')"
                  height="auto"
                  width="15"
                  class="mr-1"
                />
                <b-img
                  v-if="pdf.includes(getFileExtension(remark.document_url))"
                  :src="require('@/assets/images/icons/pdf.png')"
                  height="auto"
                  width="15"
                  class="mr-1"
                />
                <b-img
                  v-if="docs.includes(getFileExtension(remark.document_url))"
                  :src="require('@/assets/images/icons/doc.png')"
                  height="auto"
                  width="15"
                  class="mr-1"
                />
                <span class="align-bottom">{{ 'document' +'.'+ getFileExtension(remark.document_url) }}</span>
              </a>
            </span>
          </div>
        </app-timeline-item>
      </app-timeline>
    </b-row>

    <add-remark
      v-if="is_edit || is_edit_opportunity"
      :activity_ids="activity_ids"
      :date_limit="date_limit"
      :remarkmodaltitle="remarkmodaltitle"
      @loadAllRemarks="loadAllRemarks"
    />
    <b-button
      v-if="is_edit || is_edit_opportunity"
      variant="primary"
      class="addPlusBtn"
      @click="addRemark"
    >
      <feather-icon icon="PlusIcon" />
    </b-button>
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { useUtils } from '@core/libs/i18n'
import AddRemark from './AddRemark.vue'
import store from '@/store'
import constants from '@/constants'
import {
  images, docs, pdf, hideLoader, showLoader,
} from '@/utils/common'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    AddRemark,
  },
  props: ['close_remark', 'lead_id', 'activity_ids', 'is_edit', 'is_edit_opportunity', 'date_limit', 'remarkmodaltitle'],
  data() {
    return {
      images,
      docs,
      pdf,
      remarks: [],
      remarkAPI: '',
      form: {
        activity_for_id: this.activity_ids.activity_for_id ? this.activity_ids.activity_for_id : '',
        activity_for: this.activity_ids.activity_for ? this.activity_ids.activity_for : '',
        lead_id: this.activity_ids.lead_id ? this.activity_ids.lead_id : '',
        vendor_id: this.activity_ids.vendor_id ? this.activity_ids.vendor_id : '',
        opportunity_id: this.activity_ids.opportunity_id ? this.activity_ids.opportunity_id : '',
        vendor_unit_id: this.activity_ids.vendor_unit_id ? this.activity_ids.vendor_unit_id : '',
      },
    }
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  mounted() {
    document.querySelectorAll('.removeRemark').forEach(e => {
      e.remove()
    })
    // eslint-disable-next-line eqeqeq
    // if (this.activity_ids.activity_for == constants.OPPORTNUITY) {
    //   this.remarkAPI = 'vendorStore/getOpportunityRemarks'
    // // eslint-disable-next-line eqeqeq
    // } else {
    this.remarkAPI = 'vendorStore/getRemarks'
    // }
    this.getRemarks()
  },
  methods: {
    getFileExtension(file) {
      // eslint-disable-next-line prefer-const
      return file.split('.').pop()
    },
    addRemark() {
      this.$bvModal.show('addRemarkModal')
    },
    loadAllRemarks() {
      this.getRemarks()
    },
    async getRemarks() {
      showLoader()
      await store.dispatch(this.remarkAPI, this.form).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.remarks = response.data.data
          hideLoader()
        }
      }).catch(() => {
        hideLoader()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-card-fixed-height{
    height: 320px;
    overflow: auto;
    margin-bottom: 10px;
}
.tab-card-fixed-height::-webkit-scrollbar{
   display: none;
}
</style>
