<template>
  <div :class="is_edit ? 'my-2' : ''">
    <app-collapse
      type="border"
    >
      <!-- :is-visible="true" -->
      <app-collapse-item
        :is-visible="true"
        :title="t('Upcoming and OverDue')"
        class="activity-header"
      >
        <app-timeline
          v-if="upcomingList.length"
          class="ml-0 pl-1 overflow-y-auto scroll-hide"
        >

          <!-- 12 INVOICES HAVE BEEN PAID -->
          <app-timeline-item
            v-for="ulist in upcomingList"
            :key="ulist.id"
            icon="CalendarIcon"
            class="pb-10px"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0 position-relative">
              <h6
                role="button"
                @click="activityDetails(ulist.id)"
              >
                <b
                  type="button"
                  class="text-primary"
                >{{ ulist.subject_name }}</b>
              </h6>
              <small class="text-muted pr-3">{{ activityDateFormat(ulist.start_date_time) }}</small>
              <b-dropdown
                v-if="is_edit"
                variant="outline-primary timeline-dropdown"
                class="p-0 timeline-options"
              >
                <b-dropdown-item
                  @click="editActivity(ulist.activity_id)"
                >
                  Edit
                </b-dropdown-item>
              </b-dropdown>

            </div>
            <h6 v-if="ulist.purpose">
              {{ `${ulist.purpose}` }}
            </h6>
            <b-collapse
              :id="`activity-details${ulist.id}`"
              class=""
            >
              <b-avatar-group
                class="mb-5px"
                size="33"
              >
                <b-avatar
                  v-for="avatar in ulist.contact_data"
                  :key="avatar.id"
                  v-b-tooltip.hover.bottom="`${avatar.name}`"
                  button
                  :src="avatar.business_card"
                  class="pull-up"
                  @click="contactDataModel(avatar)"
                />
              </b-avatar-group>
              <h6 v-if="ulist.discussion">
                {{ `${ulist.discussion}` }}
              </h6>
              <h6 v-if="ulist.outcome">
                {{ `Outcome: ${ulist.outcome}` }}
              </h6>
              <span v-if="ulist.attachment">
                <b-img
                  v-if="images.includes(getFileExtension(ulist.attachment))"
                  :src="require('@/assets/images/icons/jpg.png')"
                  height="auto"
                  width="15"
                  class="mr-1"
                />
                <b-img
                  v-if="pdf.includes(getFileExtension(ulist.attachment))"
                  :src="require('@/assets/images/icons/pdf.png')"
                  height="auto"
                  width="15"
                  class="mr-1"
                />
                <b-img
                  v-if="docs.includes(getFileExtension(ulist.attachment))"
                  :src="require('@/assets/images/icons/doc.png')"
                  height="auto"
                  width="15"
                  class="mr-5"
                />
                <a
                  :href="ulist.attachment"
                  target="_blank"
                ><span class="align-bottom">attachment.{{ getFileExtension(ulist.attachment) }}</span>
                </a>
              </span>
            </b-collapse>
          </app-timeline-item>
        </app-timeline>
        <b-card
          v-else
          class="border-0 mt-1 d-flex h-100 justify-content-center align-items-center"
        >
          <h6>No Upcoming Activities found</h6>
        </b-card>
      </app-collapse-item>
      <!-- <infinite-loading
        :distance="50"
        @infinite="upcomingInfiniteHandler"
      >
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading> -->
    </app-collapse>
    <app-timeline
      v-if="allList.length"
      class="border mt-1 px-1 pb-1 pl-2rem ml-0 overflow-y-auto scroll-hide br-5"
    >
      <app-timeline-item
        v-for="(ulist, i) in allList"
        :key="ulist.id"
        icon="CalendarIcon"
        class="pb-10px"
      >
        <div
          v-if="i == 0 && ulist.month !== '' || i > 0 && ulist.month !== allList[i-1].month"
          class="monthHeader"
        >{{ monthsfull[ulist.month] }} {{ ulist.year }} </div>
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0 position-relative"
          :class="i == 0 && ulist.month || i > 0 && ulist.month !== allList[i-1].month ? 'mt-35' : '' "
        >
          <h6
            role="button"
            @click="activityDetails(ulist.id)"
          >
            <b
              type="button"
              class="text-primary"
            >{{ ulist.subject_name }} </b>
          </h6>
          <small class="text-muted pr-3">{{ activityDateFormat(ulist.start_date_time) }}</small>
          <b-dropdown
            v-if="is_edit"
            variant="outline-primary timeline-dropdown"
            class="p-0 timeline-options"
          >
            <b-dropdown-item @click="editActivity(ulist.activity_id)">
              Edit
            </b-dropdown-item>
          </b-dropdown>

        </div>
        <h6 v-if="ulist.purpose">
          {{ `${ulist.purpose}` }}
        </h6>
        <b-collapse
          :id="`activity-details${ulist.id}`"
          class=""
        >
          <b-avatar-group
            class="mb-5px"
            size="33"
          >
            <b-avatar
              v-for="avatar in ulist.contact_data"
              :key="avatar.id"
              v-b-tooltip.hover.bottom="`${avatar.name}`"
              button
              :src="avatar.business_card"
              class="pull-up"
              @click="contactDataModel(avatar)"
            />
          </b-avatar-group>
          <h6 v-if="ulist.discussion">
            {{ `${ulist.discussion}` }}
          </h6>
          <h6 v-if="ulist.outcome">
            {{ `Outcome: ${ulist.outcome}` }}
          </h6>
          <span v-if="ulist.attachment">
            <b-img
              v-if="images.includes(getFileExtension(ulist.attachment))"
              :src="require('@/assets/images/icons/jpg.png')"
              height="auto"
              width="15"
              class="mr-1"
            />
            <b-img
              v-if="pdf.includes(getFileExtension(ulist.attachment))"
              :src="require('@/assets/images/icons/pdf.png')"
              height="auto"
              width="15"
              class="mr-1"
            />
            <b-img
              v-if="docs.includes(getFileExtension(ulist.attachment))"
              :src="require('@/assets/images/icons/doc.png')"
              height="auto"
              width="15"
              class="mr-5"
            />
            <a
              :href="ulist.attachment"
              target="_blank"
            ><span class="align-bottom">attachment.{{ getFileExtension(ulist.attachment) }}</span>
            </a>
          </span>
        </b-collapse>
      </app-timeline-item>
    </app-timeline>
    <infinite-loading
      :distance="50"
      @infinite="infiniteHandler"
    >
      <div slot="no-more" />
      <div slot="no-results" />
    </infinite-loading>
    <!-- <b-card
      v-else
      class="border-0 mt-1 d-flex h-100 justify-content-center align-items-center"
    >
      <h6>No Activities found</h6>
    </b-card> -->
    <reminder-contact-detail-model
      :view-contact-modal="`viewActivityContactModal`"
      :user-data="contactDataReminder"
    />
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import { useUtils } from '@core/libs/i18n'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import InfiniteLoading from 'vue-infinite-loading'
import ReminderContactDetailModel from './ReminderContactDetailModel.vue'

import {
  images, docs, pdf, monthsfull, activityDateFormat,
} from '@/utils/common'
import store from '@/store'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    AppTimelineItem,
    InfiniteLoading,
    ReminderContactDetailModel,
  },
  directives: {
    Ripple,
  },
  props: {
    dataids: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    // is_edit: {
    //   type: Number,
    //   required: true,
    // },
  },
  data() {
    return {
      images,
      docs,
      pdf,
      contactDataReminder: [],
      monthsfull,
      form: {
        activity_for: this.dataids.activity_for,
        activity_for_id: this.dataids.activity_for_id,
        size: 5,
        page: 1,
        upcomingPage: 1,
      },
      upcomingList: [],
      allList: [],
      newMonth: '',
      is_edit: false,
    }
  },
  watch: {
    dataids: {
      deep: true,
      handler(val) {
        this.form.lead_id = val.lead_id
        this.form.vendor_id = val.vendor_id
        this.form.unit_id = val.unit_id
      },
    },
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  // created() {
  //   this.getUpcomingList()
  //   this.getAllList()
  // },
  created() {
    this.getUpcomingList()
    this.$root.$on('refreshActivity', () => {
      this.form.page = 1
      this.upcomingList = []
      this.allList = []
      this.getUpcomingList()
      this.getAllList()
    })
  },
  methods: {
    contactDataModel(avatar) {
      this.$bvModal.show('viewActivityContactModal')
      this.contactDataReminder = avatar
    },
    activityDetails(id) {
      this.$root.$emit('bv::toggle::collapse', `activity-details${id}`)
    },
    updateMonth(m) {
      this.newMonth = m
    },
    activityDateFormat(date) {
      return activityDateFormat(date)
    },
    infiniteHandler($state) {
      store.dispatch('activityStore/getAllList', this.form).then(response => {
        if (response.data.data.data.length) {
          this.allList.push(...response.data.data.data)
          this.form.page += 1
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    upcomingInfiniteHandler($state) {
      store.dispatch('activityStore/getUpcomingActivityList', this.form).then(response => {
        if (response.data.data.data.length) {
          this.upcomingList.push(...response.data.data.data)
          this.form.upcomingPage += 1
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    getFileExtension(file) {
      // eslint-disable-next-line prefer-const
      return file.split('.').pop()
    },
    getUpcomingList() {
      store.dispatch('activityStore/getUpcomingActivityList', this.form).then(response => {
        this.upcomingList = response.data.data.data
      })
    },
    editActivity(id) {
      this.$emit('getActivityById', id)
    },
    getAllList() {
      store.dispatch('activityStore/getAllList', this.form).then(response => {
        this.allList.push(...response.data.data.data)
        this.form.page += 1
      })
    },
  },
}
</script>

<style lang="css">
.mb-5px{
  margin-bottom: 5px;
}
.br-5{
  border-radius: 5px;
}
.timeline-dropdown{
  padding: 1px 1px;
}
.timeline-dropdown::after{
      right: 0px;
    left: auto;
    transform: rotate(0deg) !important;
}
.activity-header .card-header{
      background: linear-gradient(to right, rgba(99, 99, 248, 0.199), rgba(99, 99, 248, 0.199), rgba(255, 128, 255, 0.281));
      padding-top: 0.2rem !important;
      padding-bottom: 0.2rem !important;
}
.mh-168 {
  max-height: 168px;
}
.mh-240 {
  max-height: 240px;
}
.pb-10px{
  padding-bottom: 10px !important;
}
.overflow-y-auto{
  overflow-y: scroll;
  overflow-x: unset;
}
.scroll-hide::-webkit-scrollbar{
  display: none;
}
.timeline-options{
  position: absolute;
  right: 0;
}
.pl-2rem{
  padding-left: 2rem !important;
}
.mt-35{
  margin-top: 35px;
}
.monthHeader {
  position: absolute;
    transform: translateX(-64px);
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 12px;
    background: linear-gradient(to right, rgba(99, 99, 248, 0.2), rgba(99, 99, 248, 0.2), rgba(255, 128, 255, 0.282));
    font-weight: 500;
    font-size: 1.1rem;
    top: -35px;
    width: calc(100% + 63px);
}
</style>
