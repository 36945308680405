<template>
  <div class="tab-card-fixed-height">
    <div v-if="!alreadysubmitted">
      <feather-icon
        v-if="cancelVisible"
        icon="XCircleIcon"
        class="text-danger position-absolute right-10 font-medium-3 cursor-pointer"
        @click="alreadysubmitted = true"
      />
      <b-row>
        <b-col>
          <strong>States</strong>
        </b-col>
        <b-col
          v-for="cat in categories"
          :key="cat.para_id"
        >
          <b-row>
            <b-col>
              <strong>{{ cat.para_desc }} QTY (MT.)</strong>
            </b-col>
            <b-col>
              <strong>{{ cat.para_desc }} Rate</strong>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        v-for="(state, i) in states"
        :key="state.state_id"
        class="mt-1"
      >
        <b-col>
          {{ state.state_name }}
        </b-col>

        <b-col
          v-for="(cat, ind) in categories"
          :key="cat.para_id"
        >
          <b-row>
            <b-col>
              <b-input
                v-if="loaded"
                v-model="form.qty_data[i].category[ind].qty"
                type="text"
                maxlength="15"
                autocomplete="off"
                placeholder="Quantity"
                @keypress="onlyNum"
              />
            </b-col>
            <b-col>
              <b-input
                v-if="loaded"
                v-model="form.qty_data[i].category[ind].rate"
                type="text"
                maxlength="15"
                autocomplete="off"
                placeholder="Rate"
                @keypress="onlyNum"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-col
        cols="12"
        class="text-center mt-1"
      >
        <b-button
          type="submit"
          variant="primary"
          class="mr-1"
          @click="submitStateQunatity"
        >
          Submit
        </b-button>
        <b-button
          v-if="cancelVisible"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="danger"
          class="mr-1"
          @click="alreadysubmitted = true"
        >
          <!-- @click="queAnsData.length ? viewInformation = true : ''" -->
          Cancel
        </b-button>
      </b-col>
    </div>
    <div
      v-else
      class=" h-100 "
    >
      <import-state-wise-quantity-modal
        :import-excel-data="importExcelData"
      />
      <div class="text-right">
        <feather-icon
          v-if="true"
          icon="EditIcon"
          class="text-info cursor-pointer font-medium-2"
          @click="editInformation"
        />
        <button type="button" class="btn btn-primary ml-1 mb-1" @click="importExcel()"> Import</button>
        <button type="button" class="btn btn-primary ml-1 mb-1" @click="exportToExcel()"> Export</button>
      </div>
      <div
        class="table-responsive h-100-20 table-scrollbar"
      >
        <table class="table table-bordered table-hover table-striped ">
          <thead>
            <tr>
              <th rowspan="2">
                State</th>
              <th
                v-for="cat in categories"
                :key="cat.para_id"
              >
                {{ cat.para_desc }}
              </th>
            </tr>
            <tr>
              <th
                v-for="cat in categories"
                :key="cat.para_id"
              >
                <b-row>
                  <b-col>
                    QTY (MT.)
                  </b-col>
                  <b-col>
                    Rate
                  </b-col>
                  <b-col>
                    Total
                  </b-col>
                </b-row>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(state, i) in states"
              :key="state.state_id"
            >
              <td>{{ state.state_name }}</td>
              <td
                v-for="(cat, ind) in categories"
                :key="cat.para_id"
              >
                <b-row>
                  <b-col>
                    {{ form.qty_data[i].category[ind].qty }}
                  </b-col>
                  <b-col>
                    {{ form.qty_data[i].category[ind].rate }}
                  </b-col>
                  <b-col>
                    {{ form.qty_data[i].category[ind].qty * form.qty_data[i].category[ind].rate }}
                  </b-col>
                </b-row>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>
                Total
              </th>
              <th>
                <b-row>
                  <b-col>
                    {{ totalArr[1] }}
                  </b-col>
                  <b-col>
                    {{ totalArr[2] }}
                  </b-col>
                  <b-col>
                    {{ totalArr[3] }}
                  </b-col>
                </b-row>
              </th>
              <th>
                <b-row>
                  <b-col>
                    {{ totalArr[4] }}
                  </b-col>
                  <b-col>
                    {{ totalArr[5] }}
                  </b-col>
                  <b-col>
                    {{ totalArr[6] }}
                  </b-col>
                </b-row>
              </th>
              <th>
                <b-row>
                  <b-col>
                    {{ totalArr[7] }}
                  </b-col>
                  <b-col>
                    {{ totalArr[8] }}
                  </b-col>
                  <b-col>
                    {{ totalArr[9] }}
                  </b-col>
                </b-row>
              </th>
            </tr>
            <tr>
              <th>
                WTD Avg Rate
              </th>
              <th>
                {{ (totalArr[3]/totalArr[1]).toFixed(2) }}
              </th>
              <th>
                {{ (totalArr[6]/totalArr[4]).toFixed(2) }}
              </th>
              <th>
                {{ (totalArr[9]/totalArr[7]).toFixed(2) }}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// import vSelect from 'vue-select'
import 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import * as XLSX from 'xlsx'
import {
  failedToast,
  hideLoader, onlyNumber, showLoader, successToast,
} from '@/utils/common'
import store from '@/store'
import constants from '@/constants'
import excel from '@/excel'
import ImportStateWiseQuantityModal from './ImportStateWiseQuantityModal.vue'

export default {
  name: 'StateWiseQunatity',
  components: {
    ImportStateWiseQuantityModal,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      importExcelData: [],
      loaded: false,
      alreadysubmitted: false,
      cancelVisible: false,
      states: [],
      categories: [],
      form: {
        opportunity_id: this.$route.params.id,
        qty_data: [],
      },
      tableData: [],
      totalArr: [],
    }
  },
  mounted() {
    this.getStateList()
    this.getStateQuantityCategory()
    this.getStateQunatity()
    this.$root.$on('refreshStateQunatity', () => {
      this.getStateQunatity()
    })
  },
  methods: {
    exportToExcel() {
      excel.methods.exportToExcel(this.tableData)
    },
    importExcel() {
      this.importExcelData = []
      this.$bvModal.show('importStateWiseQuantityModal')
    },
    onlyNum(a) {
      return onlyNumber(a)
    },
    async getStateList() {
      await store.dispatch('getCountryList/getStateList', { country_id: '' }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.states = response.data.data
          this.states.forEach(state => {
            this.form.qty_data.push({ state_id: state.state_id, category: [] })
          })
        }
      })
    },
    async getStateQuantityCategory() {
      await store.dispatch('opportunityStore/stateQuantityCategory', '').then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.categories = response.data.data
          this.form.qty_data.forEach(f => {
            this.categories.forEach(cat => {
              f.category.push({ id: cat.para_id, qty: '' })
            })
          })
          this.loaded = true
        }
      })
    },
    editInformation() {
      this.alreadysubmitted = false
    },
    async submitStateQunatity() {
      showLoader()
      await store.dispatch('questionsStore/saveStateQuantityCategory', this.form).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.alreadysubmitted = true

          this.cancelVisible = true
          this.getStateQunatity()
          successToast(response.data.msg)
          hideLoader()
        } else {
          failedToast(response.data.msg)
        }
      }).catch(e => {
        failedToast(e.msg)
        hideLoader()
      })
    },
    async getStateQunatity() {
      showLoader()
      await store.dispatch('questionsStore/getStateQuantityCategory', this.form).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          if (response.data.data.length) {
            this.form.qty_data = response.data.data
            this.tableData = response.data.exportData
            this.alreadysubmitted = true
            this.cancelVisible = true
            this.totalArr = ['TOTAL', 0, 0, 0, 0, 0, 0, 0, 0, 0]
            response.data.exportData.forEach((element, index) => {
              if (index > 0) {
                element.forEach((value, i) => {
                  if (i > 0) {
                    this.totalArr[i] = +this.totalArr[i] + value
                  }
                })
              }
            })
            this.tableData.push(this.totalArr)
          }
          hideLoader()
        } else {
          failedToast(response.data.msg)
        }
      }).catch(e => {
        failedToast(e.msg)
        hideLoader()
      })
    },
  },
}
</script>

<style lang="scss">
.tab-card-fixed-height{
    height: 320px;
    min-height: 320px;
    height: calc(100vh - 504px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 10px;
}
.tab-card-fixed-height-noscroll {
   min-height: 320px;
    height: calc(100vh - 504px);
    overflow: hidden;
    margin-bottom: 10px;
}
.tab-card-fixed-height::-webkit-scrollbar{
display: none;
}
.h-100-20{
    height: calc(100% - 20px);
}
.mb-5px{
  margin-bottom: 5px;
}

table thead {
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
}
.mh-230{
    max-height: 275px;
}
.right-10{
  right: 10px;
  z-index: 999999;
}
.table-scrollbar::-webkit-scrollbar-track {
  background: rgb(211, 210, 210);
}
.table-scrollbar::-webkit-scrollbar-thumb{
    /* display: none; */
    background-color: #7367f0;
    border-radius: 6px;
}
.table-scrollbar::-webkit-scrollbar{
    /* display: none; */
    /* scrollbar-color: red; */
    width: 10px;
}
table th .col, table td .col {
  display: table;
}
</style>
