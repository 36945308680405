<template>
  <b-card
    v-if="false"
    no-body
    class="card-statistics"
  >
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(count, i) in counts"
          :key="i"
          sm="3"
        >
          <div class="lead-link-tooltip position-relative">
            <a
              href="#"
            > <span>
                <feather-icon :icon="count.title == 'Leads' ? 'StarIcon' : ''" />
              </span>
              {{ count.title }} <span> ({{ count.count_value }})</span></a>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import store from '@/store'
import constants from '@/constants'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    FeatherIcon,
  },
  props: ['vendor_id'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      counts: [],
      statsData: '',
      customClass: 'mb-2 mb-xl-0',
      icon: 'UserIcon',
      color: 'light-danger',
    }
  },
  mounted() {
    this.getQuickLinksCount()
  },
  methods: {
    async getQuickLinksCount() {
      await store.dispatch('DashboardApi/getQuickLinkCount', { vendor_id: this.vendor_id }).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.counts = response.data.data
        }
      })
    },
  },
}
</script>

<style>
.quick-link-lead-tootip {
    display: none;
}
.position-relative:hover .quick-link-lead-tootip {
    display: block;
    width: 300px;
    height: auto;
    background: white;
    padding: 10px;
    box-shadow: 1px 10px 20px #ccc;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
}
</style>
