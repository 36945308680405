var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tabs',[_c('b-tab',{attrs:{"title":_vm.t('New Activity')}},[_c('validation-observer',{ref:"addActivity"},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Task'),"label-for":"vi-task-title"}},[_c('div',{staticClass:"d-flex"},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"Subject","vid":"vi-task-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0 w-100",class:errors[0] ? 'border-red-vselect' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"para_value","placeholder":_vm.t('Create a Task'),"reduce":function (sub) { return sub.para_id; },"options":_vm.subject},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})]}}])}),_c('b-button',{attrs:{"variant":"primary ml-1","disabled":_vm.addBtnDisable},on:{"click":_vm.collapseAddTask}},[_vm._v(" "+_vm._s(_vm.t('Add')))])],1)])],1),_c('validation-observer',{ref:"submitTask"},[_c('div',{staticClass:"mb-1"},[_c('b-collapse',{attrs:{"id":"collapseAddTask"}},[_c('b-row',[_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":_vm.t('Contact Person'),"label-for":"vi-contact-person"}},[_c('validation-provider',{attrs:{"name":"Contact Person","vid":"vi-contact-person","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",class:errors[0] ? 'border-red-vselect' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","multiple":"","placeholder":_vm.t('Select Contact Person'),"reduce":function (ct) { return ct.id; },"options":_vm.allContactList},model:{value:(_vm.form.contact),callback:function ($$v) {_vm.$set(_vm.form, "contact", $$v)},expression:"form.contact"}})]}}])})],1),_c('b-form-group',{staticClass:"required col-md-6",attrs:{"label":_vm.t('Date Time'),"label-for":"vi-datetime"}},[(_vm.addActivityDateTimeDisable)?_c('flat-pickr',{staticClass:"form-control",class:_vm.date_error ? 'border-red' : '',attrs:{"placeholder":_vm.t('Select Date Time'),"config":{ dateFormat: 'Y-m-d h:i K', enableTime: true, disable: [{ from: '1970-08-20', to: new Date().setDate(new Date().getDate() - (_vm.date_limit + 1)) }] }},on:{"input":_vm.changeDateTime},model:{value:(_vm.form.start_date_time),callback:function ($$v) {_vm.$set(_vm.form, "start_date_time", $$v)},expression:"form.start_date_time"}}):_vm._e(),(!_vm.addActivityDateTimeDisable)?_c('b-form-input',{class:_vm.errors[0] ? 'border-red' : '',attrs:{"id":"vi-datetime","autocomplete":"off","disabled":"","placeholder":_vm.t('Select Date Time')},model:{value:(_vm.form.start_date_time),callback:function ($$v) {_vm.$set(_vm.form, "start_date_time", $$v)},expression:"form.start_date_time"}}):_vm._e()],1)],1),_c('b-row',[_c('b-form-group',{staticClass:"col-md-12",attrs:{"label":_vm.t('Visitors (optional)'),"label-for":"vi-contact-person"}},[_c('validation-provider',{attrs:{"name":"Visitors","vid":"vi-contact-person","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",class:errors[0] ? 'border-red-vselect' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"company_user_name","multiple":"","placeholder":_vm.t('Select User'),"reduce":function (ct) { return ct.companyuserid; },"options":_vm.usersList},model:{value:(_vm.form.users),callback:function ($$v) {_vm.$set(_vm.form, "users", $$v)},expression:"form.users"}})]}}])})],1)],1),_c('b-row',[_c('b-form-group',{staticClass:"required col-md-6",attrs:{"label":_vm.t('Purpose'),"label-for":"vi-purpose"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"Purpose","vid":"vi-purpose","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-purpose","autocomplete":"off","placeholder":_vm.t('Purpose')},model:{value:(_vm.form.purpose),callback:function ($$v) {_vm.$set(_vm.form, "purpose", $$v)},expression:"form.purpose"}})]}}])})],1),_c('b-form-group',{staticClass:"required col-md-6",attrs:{"label":_vm.t('Discussion'),"label-for":"vi-discussion"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"Discussion","vid":"vi-discussion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-discussion","autocomplete":"off","placeholder":_vm.t('Discussion')},model:{value:(_vm.form.discussion),callback:function ($$v) {_vm.$set(_vm.form, "discussion", $$v)},expression:"form.discussion"}})]}}])})],1)],1),_c('b-form-group',{attrs:{"label":_vm.t('Outcome'),"label-for":"vi-outcome"}},[_c('validation-provider',{staticClass:"input-group",attrs:{"name":"Outcome","vid":"vi-outcome","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-outcome","autocomplete":"off","placeholder":_vm.t('Outcome')},model:{value:(_vm.form.outcome),callback:function ($$v) {_vm.$set(_vm.form, "outcome", $$v)},expression:"form.outcome"}})]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.t('Upload File'),"label-for":"vi-uploadFile"}},[_c('b-form-file',{attrs:{"id":"vi-uploadFile","placeholder":_vm.t('Upload File'),"browse-text":_vm.t('Browse')},model:{value:(_vm.form.attachment),callback:function ($$v) {_vm.$set(_vm.form, "attachment", $$v)},expression:"form.attachment"}})],1),_c('div',{staticClass:"d-flex justify-content-around"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitTask}},[_vm._v(" "+_vm._s(_vm.t('Submit'))+" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.cancelTask}},[_vm._v(" "+_vm._s(_vm.t('Cancel'))+" ")])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }