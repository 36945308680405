<template>
  <b-modal
    :id=template_id
    title="PDF"
    size="lg"
    hide-footer
  >
    <div
      v-if="view"
    >
      <vue-pdf-app
        :pdf="pdf"
      />
    </div>
  </b-modal>
</template>

<script>
import 'bootstrap-vue'
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
import store from '@/store'
import constants from '@/constants'

export default {
  name: 'ViewPDFModal',
  components: {
    VuePdfApp,
  },
  props: ['service_id', 'opportunity_id', 'template_id'],
  data() {
    return {
      pdf: '',
      view: false,
    }
  },
  mounted() {
    if (this.service_id) {
      store.dispatch('questionsStore/getPDFQnA', { service_id: this.service_id, opportunity_id: this.opportunity_id, template_id: this.template_id }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.pdf = response.data.data.pdf_url
        }
      })
    }
    this.$root.$on('bv::modal::shown', () => {
      this.view = true
    })
    this.$root.$on('bv::modal::hidden', () => {
      this.view = false
    })
  },
  methods: {
    openHandler(pdfApp) {
      // eslint-disable-next-line no-underscore-dangle
      window._pdfApp = pdfApp
    },
  },

}
</script>

<style lang="scss" scoped>
.pdf-app {
    height: calc(100vh - 100px);
}
</style>
