<template>
  <b-modal
    id="addDocumentModal"
    :title="t('Add Document')"
    size="md"
    hide-footer
  >
    <b-card>
      <validation-observer
        ref="addDocumentForm"
      >
        <b-form
          enctype="multipart/form-data"
          autocomplete="off"
          @submit.prevent="addDocumentSubmit"
        >
          <b-row>

            <b-col
              cols="12"
              class="p-0"
            >
              <b-form-group
                :label="t('Document')"
                label-for="vi-document"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Document"
                  vid="vi-Document"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-file
                      id="vi-document"
                      v-model="form.document"
                      :class="errors[0] ? 'border-red-file' : ''"
                      :browse-text="t('Browse')"
                      type="file"
                      :placeholder="t('Upload Document')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="p-0"
            >
              <b-form-group
                :label="t('Document Name')"
                label-for="vi-description"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  vid="vi-description"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input
                      id="vi-description"
                      v-model="form.name"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Document Name')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
              >
                {{ t('Submit') }}
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="documentModalCancel"
              >
                {{ t('Cancel') }}
              </b-button>
            </b-col>
          </b-row></b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import constants from '@/constants'
import { hideLoader, showLoader } from '@/utils/common'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ['activity_ids'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      addRemarkAPI: '',
      form: {
        activity_for_id: this.activity_ids.activity_for_id ? this.activity_ids.activity_for_id : '',
        activity_for: this.activity_ids.activity_for ? this.activity_ids.activity_for : '',
        lead_id: this.activity_ids.lead_id ? this.activity_ids.lead_id : '',
        vendor_id: this.activity_ids.vendor_id ? this.activity_ids.vendor_id : '',
        opportunity_id: this.activity_ids.opportunity_id ? this.activity_ids.opportunity_id : '',
        vendor_unit_id: this.activity_ids.vendor_unit_id ? this.activity_ids.vendor_unit_id : '',
        document: [],
        name: '',
      },
    }
  },
  watch: {
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (a, componentId) => {
      if (componentId === 'addDocumentModal') {
        this.form = {
          activity_for_id: this.activity_ids.activity_for_id ? this.activity_ids.activity_for_id : '',
          activity_for: this.activity_ids.activity_for ? this.activity_ids.activity_for : '',
          lead_id: this.activity_ids.lead_id ? this.activity_ids.lead_id : '',
          vendor_id: this.activity_ids.vendor_id ? this.activity_ids.vendor_id : '',
          opportunity_id: this.activity_ids.opportunity_id ? this.activity_ids.opportunity_id : '',
          vendor_unit_id: this.activity_ids.vendor_unit_id ? this.activity_ids.vendor_unit_id : '',
          document: [],
          name: '',
        }
      }
    })
    // eslint-disable-next-line eqeqeq
    if (this.activity_ids.activity_for == constants.LEAD) {
      this.addRemarkAPI = 'vendorStore/addDocument'
      // eslint-disable-next-line eqeqeq
    } else if (this.activity_ids.activity_for == constants.OPPORTNUITY) {
      this.addRemarkAPI = 'vendorStore/addOpportunityDocument'
    }
  },
  methods: {
    addDocumentSubmit() {
      // eslint-disable-next-line prefer-const
      let formData = new FormData()
      Object.entries(this.form).forEach(
        ([key, value]) => {
          formData.append(key, value)
        },
      )
      this.$refs.addDocumentForm.validate().then(success => {
        if (success) {
          this.addDocument(formData)
        }
      })
    },
    async addDocument(formData) {
      showLoader()
      await store.dispatch(this.addRemarkAPI, formData).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.$bvModal.hide('addDocumentModal')
          this.$emit('loadAllDocuments')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: '',
            },
          })
          this.$refs.addDocumentForm.reset()
          hideLoader()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          hideLoader()
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: e.msg,
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    documentModalCancel() {
      this.$bvModal.hide('addDocumentModal')
    },
  },
}
</script>

<style>

</style>
