<template>
  <div
    id="contact_card"
    :class="itemView == 'grid-view' ? 'tab-card-fixed-height' : itemView == 'list-view' ? 'tab-card-fixed-height-noscroll' : ''"
  >
    <b-row
      v-if="!allContactList.length && itemView == 'grid-view'"
      class="mt-25"
    >
      <b-col
        xl="12"
        class="padding-b-28"
      >
        <b-card class="h-100">
          <b-row class="h-100 align-items-center">
            <b-col
              cols="12"
              class="pr-0 text-center"
            >
              {{ t('No Contacts Found') }}
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
        class="grid-list-changer"
      >
        <b-form-radio-group
          v-model="itemView"
          class="position-absolute grid-list-button list item-view-radio-group right-0"
          buttons
          size="sm"
          button-variant="outline-primary"
        >
          <b-form-radio
            v-for="option in itemViewOptions"
            :key="option.value"
            :value="option.value"
          >
            <feather-icon
              :icon="option.icon"
              size="12"
            />
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>
    <b-row
      v-if="itemView == 'grid-view'"
      class="mt-25"
    >
      <b-col
        v-for="(contact, i) in allContactList"
        :key="i"
        xl="4"
        class="padding-b-28"
      >
        <b-card
          class="h-100 card-contact-list contact-scroll"
        >
          <a
            v-if="is_edit"
            href="javascript:void(0)"
            class="position-absolute contact-edit"
            @click="edtiContact(contact.contact_id)"
          >
            <feather-icon icon="EditIcon" />
          </a>
          <b-row class="">
            <b-col
              cols="4"
              class="pr-0 text-center"
            >
              <b-avatar
                size="40"
                variant="light-primary"
                :src="null"
                class="badge-minimal"
                badge-variant="success"
              />
              <div class="d-flex justify-content-center pt-1">
                <a
                  v-if="contact.landline"
                  class="padding-2"
                  :href="`tel:`+contact.landline + (contact.extention ? '-'+contact.extention : '')"
                >
                  <feather-icon
                    v-b-tooltip.hover.focus.v-primary
                    icon="PhoneIcon"
                    variant="primary"
                    :title="contact.landline + (contact.extention ? '-'+contact.extention : '')"
                  />
                </a>
                <a
                  v-if="contact.mobile"
                  class="padding-2"
                  :href="`tel:`+contact.mobile"
                >
                  <feather-icon
                    v-b-tooltip.hover.focus.v-primary
                    icon="SmartphoneIcon"
                    variant="primary"
                    :title="contact.mobile"
                  />
                </a>
                <!--  -->
                <span
                  v-if="contact.business_card && images.includes(getFileExtension(contact.business_card))"
                  class="padding-2 text-primary padding-l-4 custom-tooltip-main"
                >
                  <vue-pure-lightbox
                    :id="`lightbox${i}`"
                    thumbnail="/img/creditcard.svg"
                    :images="[
                      contact.business_card,
                    ]"
                    @input="openLightbox"
                  />
                  <div class="custom-tooltip justify-content-center">
                    <vue-pure-lightbox
                      class="tooltip-img"
                      :thumbnail="contact.business_card"
                      :images="[
                        contact.business_card,
                      ]"
                      @input="openLightbox"
                    />
                  </div>
                </span>
                <span
                  v-else-if="contact.business_card"
                  class="d-flex"
                >
                  <a
                    :href="contact.business_card"
                    target="_blank"
                    class="padding-2 text-primary padding-l-4"
                  >
                    <img
                      src="/img/creditcard.svg"
                      alt=""
                    >
                  </a>
                </span>
              </div>
            </b-col>
            <b-col cols="8">
              <div class="font-weight-bolder text-primary">
                {{ contact.name }}
              </div>
              <div>
                <small
                  v-if="contact.designation"
                  class=""
                >
                  {{ contact.designation | uppercase }}
                </small>
              </div>
              <div>
                <small class="">
                  {{ contact.email }}
                </small>
              </div>
              <div class="">
                <small
                  v-for="service in contact.service_data"
                  :key="service.id"
                >
                  <b-badge
                    v-if="service.service_id == constants.OTHER_SERVICE_ID"
                    class="m-10"
                    variant="success"
                  >{{ service.other_service }}</b-badge>
                  <b-badge
                    v-else
                    class="m-10"
                    variant="success"
                  >{{ service.service_name }}</b-badge>
                </small>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-table
      v-if="itemView == 'list-view'"
      class="mt-25 contact-table-scroll h-100 pb-2"
      striped
      hover
      sticky-header
      responsive
      :items="contacts_table_items"
      :fields="contacts_table_fields"
    >
      <template #cell(mobile)="data">
        <a
          v-if="data.item.mobile"
          :href="`tel:${data.item.mobile}`"
        >{{ data.item.mobile }}</a>
      </template>
      <template #cell(landline)="data">
        <a
          v-if="data.item.landline"
          :href="`tel:${data.item.landline}`"
        >{{ data.item.landline + (data.item.extention ? '-'+data.item.extention : '') }}</a>
      </template>
      <template #cell(services)="data">
        <small
          v-for="service in data.item.service_data"
          :key="service.id"
        >
          <b-badge
            class="m-10"
            variant="success"
          >{{ service.service_name }}</b-badge>
        </small>
      </template>
      <template #cell(business_card)="data">
        <a
          v-if="data.item.business_card"
          :href="data.item.business_card"
          target="_blank"
        >
          <img
            src="/img/download.svg"
            alt=""
            width="20px"
          >
        </a>
        <span v-else> {{ }} </span>
      </template>
      <template #cell(action)="data">
        <a
          v-if="is_edit"
          href="javascript:void(0)"
          class=""
          @click="edtiContact(data.item.contact_id)"
        >
          <feather-icon icon="EditIcon" />
        </a>
      </template>
      <template
        #table-caption
      >
        <div
          v-if="!allContactList.length"
          class="text-center"
        >
          {{ t('No Contacts Found') }}
        </div>
        <infinite-loading
          ref="infiniteContactLoading"
          :distance="10"
          @infinite="infiniteContactsHandler"
        >
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
      </template>
    </b-table>
    <b-col
      xl="12"
      class=""
    >
      <infinite-loading
        ref="infiniteContactLoading"
        :distance="10"
        @infinite="infiniteContactsHandler"
      >
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </b-col>
    <edit-contact-modal
      v-if="is_edit && activity_id"
      :activity_ids="activity_id"
      :service_data="service_data"
      :edit-contact-data="editContactData"
      @loadAllContact="loadAllContact"
    />
    <b-button
      v-if="is_edit"
      variant="primary"
      class="addPlusBtn"
      @click="addUser"
    >
      <feather-icon icon="PlusIcon" />
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BBadge, BCol, BRow, BAvatar, VBTooltip,
} from 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import InfiniteLoading from 'vue-infinite-loading'
import VuePureLightbox from 'vue-pure-lightbox'
import $ from 'jquery'
import store from '@/store'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import EditContactModal from './EditContactModal.vue'
import constants from '@/constants'
import {
  hideLoader, showLoader, getFileExtension, images,
} from '@/utils/common'

export default {
  components: {
    BCard,
    BBadge,
    BCol,
    BRow,
    BAvatar,
    VuePureLightbox,
    InfiniteLoading,
    FeatherIcon,
    EditContactModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['lead_id', 'vendor_id', 'vendor_unit_id', 'opportunity_id', 'service_data', 'activity_ids', 'is_edit'],
  setup() {
    const { t } = useUtils()
    return { t, getFileExtension, images }
  },
  data() {
    return {
      constants,
      index: null,
      itemView: 'grid-view',
      allContactList: [],
      dispatchPath: '',
      editContactData: [],
      contacts_table_items: [],
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      form: {
        size: 5,
        pageNumber: 1,
        opportunity_id: this.opportunity_id,
        lead_id: this.lead_id,
        vendor_id: this.vendor_id,
        vendor_unit_id: this.vendor_unit_id,
      },
      toggleLightbox: false,
      business_cards: [],
      contacts_table_fields: [],
    }
  },
  computed: {
    activity_id() {
      return this.activity_ids
    },
  },
  watch: {
    itemView(val) {
      // eslint-disable-next-line eqeqeq
      if (val == 'list-view') {
        $(`input[value="${val}"]`).parents('.tab-card-fixed-height')[0].scrollTop = 0
      }
    },
  },
  created() {
    if (this.is_edit) {
      this.contacts_table_fields = ['name', 'email', 'mobile', 'landline', 'designation', 'services', 'business_card', 'action']
    } else {
      this.contacts_table_fields = ['name', 'email', 'mobile', 'landline', 'designation', 'services', 'business_card']
    }
  },
  mounted() {
    if (this.lead_id) {
      this.dispatchPath = 'ManageLeads/getLeadContactData'
    } else if (this.vendor_id) {
      this.dispatchPath = 'vendorStore/getVendorContactData'
    } else if (this.opportunity_id) {
      this.dispatchPath = 'vendorStore/getOpportunityContactData'
    }
  },
  methods: {
    openLightbox(vent) {
      this.toggleLightbox = vent
    },
    addUser() {
      this.$bvModal.show('editContactModal')
    },
    infiniteContactsHandler($state) {
      store.dispatch(this.dispatchPath, this.form).then(response => {
        if (response.data.data.data.length) {
          this.allContactList = response.data.data.data
          this.contacts_table_items = response.data.data.data
          this.form.pageNumber += 1
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    loadAllContact() {
      this.form.pageNumber = 1
      this.allContactList = []
      this.contacts_table_items = []
      this.$refs.infiniteContactLoading.stateChanger.reset()
      // this.infiniteContactsHandler()
    },
    async edtiContact(id) {
      showLoader()
      this.editContactData = []
      if (this.lead_id) {
        await this.getContactsData(id)
      } else if (this.opportunity_id) {
        await this.getOppContactsData(id)
      }
      this.$bvModal.show('editContactModal')
      hideLoader()
    },
    getContactsData(id) {
      store.dispatch('vendorStore/getContactById', { lead_id: this.lead_id, contact_id: id }).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.editContactData = response.data.data
        }
      })
    },
    getOppContactsData(id) {
      store.dispatch('vendorStore/getOppContactById', { opportunity_id: this.opportunity_id, contact_id: id }).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.editContactData = response.data.data
        }
      })
    },
  },
}
</script>

<style>
.contact-edit{
    top: 10px;
    right: 10px;
}
  .tooltip-img img {
  height: 60px;
}
  .lightbox .lightbox__image img {
  width: 600px;
  height: auto;
  transition: opacity .3s ease-in-out;
}

.lightbox__default-loader__element{
  display: none;
}
.lazy-contact-loader{
  height: 50px;
  position: relative;
  border-radius: 10px;
}
</style>
<style lang="scss" scoped>
.contact-scroll{
    overflow: auto;
}
.contact-scroll::-webkit-scrollbar{
    display: none;
}
/* width */
.contact-table-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
.contact-table-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}
/* Handle */
.contact-table-scroll::-webkit-scrollbar-thumb {
  background: #bdb7f8;
  border-radius: 5px;
}

/* Handle on hover */
.contact-table-scroll::-webkit-scrollbar-thumb:hover {
  background: #bdb7f8;
}
.tab-card-fixed-height{
    // height: 320px;
    min-height: 320px;
    height: calc(100vh - 504px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 10px;
}
.tab-card-fixed-height-noscroll {
   min-height: 320px;
    height: calc(100vh - 504px);
    overflow: hidden;
    margin-bottom: 10px;
}
.tab-card-fixed-height::-webkit-scrollbar{
display: none;
}
.card-contact-list{
  border: 1px solid #bbb6f161;
  border-radius: 10px;
  box-shadow: 1px 1px 12px #bbb6f161 !important;
}
.card-contact-list .card-body{
  padding-bottom: 0px;
  border-radius: 10px;
  border-top: 5px solid #7367f0;
}
.custom-tooltip-main{
     position: relative;
}
.custom-tooltip{
  display: none;
}
.custom-tooltip-main::after{
  content: '';
    border-top: 6px  solid;
    transform: rotate(0deg) translate(50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    position: absolute;
    left: 0;
    top: -2px;
}
.custom-tooltip-main::after{
    display: none;
  }
.custom-tooltip{
  position: absolute;
  z-index: 99999;
  padding: 5px;
  height: 68px;
  width: 82px;
  align-items: center;
  top: -70px;
  left: -225%;
  border-radius: 5px;
  background: #7367f0;
}
.custom-tooltip-main:hover .custom-tooltip {
  display: flex;
}
.custom-tooltip-main:hover::after {
  display: block;
}
.grid-list-changer{
  position: absolute;
    top: 0;
    width: 100%;
    height: 35px;
    z-index: 2;
    background: white;
}
.dark-layout .grid-list-changer {
  background: #283046;
}
.mt-25 {
  margin-top: 25px !important;
}
.table-business-card{
  max-width: 25px !important;
}
.grid-list-button{
  top: 5px;
  right: 5px;
}

</style>
