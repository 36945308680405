<template>
  <div>
    <b-row class="tab-card-fixed-height">
      <b-col
        v-if="!reminders.length"
        xl="12"
        class="padding-b-28"
      >
        <b-card class="h-100">
          <b-row class="h-100 align-items-center">
            <b-col
              cols="12"
              class="pr-0 text-center"
            >
              {{ t('No Reminders Found') }}
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col v-else>
        <app-timeline
          v-if="reminders.length"
          class="ml-0 pl-1 overflow-y-auto scroll-hide"
        >

          <!-- 12 INVOICES HAVE BEEN PAID -->
          <app-timeline-item
            v-for="ulist in reminders"
            :key="ulist.id"
            icon="CalendarIcon"
            :variant="moment(ulist.start_date_time)"
            class="pb-10px"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0 position-relative">
              <h6
                role="button"
                @click="activityDetails(ulist.id)"
              >
                <b
                  type="button"
                  :class="moment(ulist.start_date_time) == 'success' ? 'text-success' : 'text-warning'"
                >{{ ulist.subject_name }}</b>
              </h6>
              <small class="text-muted">{{ activityDateFormat(ulist.start_date_time) }}</small>

            </div>
            <h6 v-if="ulist.purpose">
              {{ `${ulist.purpose}` }}
            </h6>
            <b-avatar-group
              class="pt-50"
              size="33"
            >
              <b-avatar
                v-for="avatar in ulist.contact_data"
                :key="avatar.id"
                v-b-tooltip.hover.bottom="`${avatar.name}`"
                button
                :src="avatar.business_card"
                class="pull-up"
                @click="contactDataModel(avatar)"
              />
            </b-avatar-group>
            <b-collapse
              :id="`activity-details-${ulist.id}`"
              class="mt-1"
            >
              <h6 v-if="ulist.discussion">
                {{ `${ulist.discussion}` }}
              </h6>
              <h6 v-if="ulist.outcome">
                {{ `Outcome: ${ulist.outcome}` }}
              </h6>
              <span v-if="ulist.attachment">
                <b-img
                  v-if="images.includes(getFileExtension(ulist.attachment))"
                  :src="require('@/assets/images/icons/jpg.png')"
                  height="auto"
                  width="15"
                  class="mr-1"
                />
                <b-img
                  v-if="pdf.includes(getFileExtension(ulist.attachment))"
                  :src="require('@/assets/images/icons/pdf.png')"
                  height="auto"
                  width="15"
                  class="mr-1"
                />
                <b-img
                  v-if="docs.includes(getFileExtension(ulist.attachment))"
                  :src="require('@/assets/images/icons/doc.png')"
                  height="auto"
                  width="15"
                  class="mr-5"
                />
                <a
                  :href="ulist.attachment"
                  target="_blank"
                ><span class="align-bottom">attachment.{{ getFileExtension(ulist.attachment) }}</span>
                </a>
              </span>
            </b-collapse>
          </app-timeline-item>
        </app-timeline>
      </b-col>
    </b-row>
    <add-reminder :activity_ids="activity_ids" />

    <reminder-contact-detail-model
      :view-contact-modal="`viewReminderContactModal`"
      :user-data="contactDataReminder"
    />
    <!-- <b-button
      variant="primary"
      class="addPlusBtn"
      @click="addReminder"
    >
      <feather-icon icon="PlusIcon" />
    </b-button> -->
  </div>
</template>

<script>
import 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { useUtils } from '@core/libs/i18n'
import moment from 'moment'
import AddReminder from './AddReminder.vue'
import ReminderContactDetailModel from './ReminderContactDetailModel.vue'
import store from '@/store'
import {
  images, pdf, docs, hideLoader, showLoader, months,
} from '@/utils/common'
import constants from '@/constants'

export default {
  components: {
    AddReminder,
    AppTimeline,
    AppTimelineItem,
    ReminderContactDetailModel,
  },
  props: ['activity_ids', 'is_edit'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      images,
      pdf,
      docs,
      reminders: [],
      form: {
        activity_for_id: this.activity_ids.activity_for_id ? this.activity_ids.activity_for_id : '',
        activity_for: this.activity_ids.activity_for ? this.activity_ids.activity_for : '',
        lead_id: this.activity_ids.lead_id ? this.activity_ids.lead_id : '',
        vendor_id: this.activity_ids.vendor_id ? this.activity_ids.vendor_id : '',
        opportunity_id: this.activity_ids.opportunity_id ? this.activity_ids.opportunity_id : '',
        vendor_unit_id: this.activity_ids.vendor_unit_id ? this.activity_ids.vendor_unit_id : '',
      },
      contactDataReminder: [],
    }
  },
  mounted() {
    if (this.$route.name === 'lead/id') {
      this.form.lead_id = this.$route.params.id
    }
    this.$root.$on('refreshActivity', () => {
      this.getReminders()
    })
    this.getReminders()
  },
  methods: {
    moment(date) {
      if (moment(date).diff(moment(), 'minute') < 15) {
        return 'warning'
      }
      return 'success'
    },
    contactDataModel(avatar) {
      this.$bvModal.show('viewReminderContactModal')
      this.contactDataReminder = avatar
    },
    activityDetails(id) {
      this.$root.$emit('bv::toggle::collapse', `activity-details-${id}`)
    },
    activityDateFormat(date) {
      // eslint-disable-next-line prefer-const
      let d = new Date(date)
      // eslint-disable-next-line prefer-const
      // eslint-disable-next-line radix
      let hrs = parseInt(d.getHours())
      const ampm = d.getHours() >= 12 ? 'pm' : 'am'
      hrs %= 12
      // eslint-disable-next-line prefer-const
      let min = `${(`0${d.getMinutes()}`).slice(-2)}`
      hrs = hrs !== 0 ? hrs : 12
      hrs = (`0${hrs}`).slice(-2)
      const ddmm = `${d.getDate()} ${months[d.getMonth()]}`
      return `${hrs}:${min} ${ampm} | ${ddmm}`
    },
    getFileExtension(file) {
      // eslint-disable-next-line prefer-const
      return file.split('.').pop()
    },
    addReminder() {
      this.$bvModal.show('addReminderModal')
    },
    async getReminders() {
      showLoader()
      await store.dispatch('vendorStore/getReminders', this.form).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.reminders = response.data.data.data
        }
      }).catch(() => {
        hideLoader()
      })
      hideLoader()
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-card-fixed-height{
    height: 320px;
    overflow: auto;
    margin-bottom: 10px;
}

</style>
