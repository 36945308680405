<template>
  <b-modal
    id="changeStatusModal"
    :title="remarkmodaltitle + ' (' + modalTitleExt + ')'"
    size="lg"
    hide-footer
  >
    <b-card>
      <validation-observer
        ref="changeStatusModal"
      >
        <b-form
          enctype="multipart/form-data"
          autocomplete="off"
          @submit.prevent="addRemarkSubmit"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="t('Task')"
                label-for="vi-task-title"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Subject"
                  vid="vi-task-name"
                  rules="required"
                >
                  <v-select
                    v-model="form.subject"
                    class="p-0 border-0 w-100"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="para_value"
                    :placeholder="t('Create a Task')"
                    :reduce="sub => sub.para_id"
                    :options="subject"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="t('Contact Person')"
                label-for="vi-contact-person"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Contact Person"
                  vid="vi-contact-person"
                  rules=""
                >
                  <v-select
                    v-model="form.contact"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    class="p-0 border-0 w-100"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    multiple
                    :placeholder="t('Select Contact Person')"
                    :reduce="ct => ct.id"
                    :options="allContactList"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="t('Date Time')"
                label-for="vi-datetime"
                class="required"
              >
                <!-- <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Date Time"
                  vid="vi-datetime"
                  rules="required"
                > -->
                <flat-pickr
                  v-model="form.start_date_time"
                  :class="date_error ? 'border-red' : ''"
                  :placeholder="t('Select Date Time')"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d h:i K', enableTime: true }"
                  @input="changeDateTime"
                />
                <!-- </validation-provider> -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="t('Purpose')"
                label-for="vi-purpose"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Purpose"
                  vid="vi-purpose"
                  rules="required"
                >
                  <b-form-textarea
                    id="vi-purpose"
                    v-model="form.purpose"
                    autocomplete="off"
                    :class="errors[0] ? 'border-red' : ''"
                    :placeholder="t('Purpose')"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="t('Discussion')"
                label-for="vi-discussion"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Discussion"
                  vid="vi-discussion"
                  rules="required"
                >
                  <b-form-textarea
                    id="vi-discussion"
                    v-model="form.discussion"
                    autocomplete="off"
                    :class="errors[0] ? 'border-red' : ''"
                    :placeholder="t('Discussion')"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="t('Outcome')"
                label-for="vi-outcome"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Outcome"
                  vid="vi-outcome"
                  rules=""
                >
                  <b-form-textarea
                    id="vi-outcome"
                    v-model="form.outcome"
                    autocomplete="off"
                    :class="errors[0] ? 'border-red' : ''"
                    :placeholder="t('Outcome')"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if=" (loseReason) "
              md="6"
            >
              <b-form-group
                :label="t('Select Reson')"
                label-for="vi-task-title"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="reason"
                  vid="vi-reason"
                  rules="required"
                >
                  <v-select
                    v-model="form.reason"
                    class="p-0 border-0 w-100"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="para_value"
                    :placeholder="t('Select Reason')"
                    :reduce="sub => sub.para_id"
                    :options="reasons"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if=" (loseReason) "
              md="6"
            >
              <b-form-group
                :label="t('Work awarded to which WMA? (other than NEPRA)')"
                label-for="vi-outcome"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="wma"
                  vid="vi-wma"
                  rules=""
                >
                  <b-form-textarea
                    id="vi-wma"
                    v-model="form.wma"
                    autocomplete="off"
                    :class="errors[0] ? 'border-red' : ''"
                    :placeholder="t('Enter WMA Name')"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="Revenue_Onboarded">
              <b-form-group
                :label="t('Applicability of PWM Rules')"
                label-for="vi-task-title"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Subject"
                  vid="vi-task-name"
                  rules="required"
                >
                  <v-select
                    v-model="form.pwm_rules"
                    class="p-0 border-0 w-100"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :placeholder="t('Select')"
                    :multiple="true"
                    :reduce="sub => sub.id"
                    :options="PWM_Rules"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="t('Upload Files')"
                label-for="vi-attachment"
                :class="attechmentReq ? 'required' : ''"
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="attachment"
                  vid="vi-attachment"
                  :rules="attechmentReq ? 'required' : ''"
                >
                  <b-form-file
                    v-model="form.attachment"
                    :class="errors[0] ? 'form-control border-red' : ''"
                    :placeholder="t('Upload File')"
                    :browse-text="t('Browse')"
                    multiple
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <template v-if="Revenue_Onboarded">
            <fieldset class="fieldset-style">
              <b-row>
                <b-col md="4">
                  <b-form-group
                    :label="t('PO Start Date')"
                    label-for="vi-start-date"
                    class=""
                  >
                    <b-input-group class="input-group-merge">
                      <flat-pickr
                        v-model="form.po_start_date"
                        :placeholder="t('Select PO Start Date')"
                        class="form-control"
                        :config="{ dateFormat: 'Y-m-d'}"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    :label="t('PO End Date')"
                    label-for="vi-end-date"
                    class=""
                  >
                    <b-input-group class="input-group-merge">
                      <flat-pickr
                        v-model="form.po_end_date"
                        class="form-control"
                        :placeholder="t('Select PO End Date')"
                        :config="{ dateFormat: 'Y-m-d'}"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    :label="t('Po Amount')"
                    label-for="vi-po_amount"
                    class=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="PoAmount"
                      vid="vi-po_amount"
                      rules="integer"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="vi-po_amount"
                          v-model="form.po_amount"
                          type="text"
                          maxlength="15"
                          autocomplete="off"
                          :class="errors[0] ? 'border-red' : ''"
                          :placeholder="t('Po Amount')"
                          @keypress="onlyNum"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="t('Payment Term')"
                    label-for="vi-type"
                    class=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      class="input-group"
                      name="Type"
                      vid="vi-type"
                      rules=""
                    >
                      <v-select
                        v-model="form.type"
                        :class="errors[0] ? 'border-red-vselect' : ''"
                        class="p-0 border-0 w-100"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :placeholder="t('Select Type')"
                        :reduce="ct => ct.id"
                        :options="typeList"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="t('Payment Amount')"
                    label-for="vi-amount"
                    class=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Amount"
                      vid="vi-amount"
                      rules="integer"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="vi-amount"
                          v-model="form.amount"
                          type="text"
                          maxlength="15"
                          autocomplete="off"
                          :class="errors[0] ? 'border-red' : ''"
                          :placeholder="t('Amount')"
                          @keypress="onlyNum"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="t('Rigid Qty(MT)')"
                    label-for="vi-rigid_qty"
                    class=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="RigidQty"
                      vid="vi-rigid_qty"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="vi-rigid_qty"
                          v-model="form.rigid_qty"
                          type="text"
                          maxlength="15"
                          autocomplete="off"
                          :class="errors[0] ? 'border-red' : ''"
                          :placeholder="t('Rigid Qty')"
                          @keypress="onlyNum"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="t('Rigid Rate(MT)')"
                    label-for="vi-rigid_rate"
                    class=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="RigidRate"
                      vid="vi-rigid_rate"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="vi-rigid_rate"
                          v-model="form.rigid_rate"
                          type="text"
                          maxlength="15"
                          autocomplete="off"
                          :class="errors[0] ? 'border-red' : ''"
                          :placeholder="t('Rigid Rate')"
                          @keypress="onlyNum"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="t('Flexible Qty(MT)')"
                    label-for="vi-flexible_qty"
                    class=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="FlexibleQty"
                      vid="vi-flexible_qty"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="vi-flexible_qty"
                          v-model="form.flexible_qty"
                          type="text"
                          maxlength="15"
                          autocomplete="off"
                          :class="errors[0] ? 'border-red' : ''"
                          :placeholder="t('Flexible Qty')"
                          @keypress="onlyNum"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="t('Flexible Rate(MT)')"
                    label-for="vi-flexible_rate"
                    class=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="FlexibleRate"
                      vid="vi-flexible_rate"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="vi-flexible_rate"
                          v-model="form.flexible_rate"
                          type="text"
                          maxlength="15"
                          autocomplete="off"
                          :class="errors[0] ? 'border-red' : ''"
                          :placeholder="t('Flexible Rate')"
                          @keypress="onlyNum"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="t('MLP Qty(MT)')"
                    label-for="vi-MLP_qty"
                    class=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="MLPQty"
                      vid="vi-MLP_qty"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="vi-MLP_qty"
                          v-model="form.MLP_qty"
                          type="text"
                          maxlength="15"
                          autocomplete="off"
                          :class="errors[0] ? 'border-red' : ''"
                          :placeholder="t('MLP Qty')"
                          @keypress="onlyNum"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="t('MLP Rate(MT)')"
                    label-for="vi-MLP_rate"
                    class=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="MLPRate"
                      vid="vi-MLP_rate"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="vi-MLP_rate"
                          v-model="form.MLP_rate"
                          type="text"
                          maxlength="15"
                          autocomplete="off"
                          :class="errors[0] ? 'border-red' : ''"
                          :placeholder="t('MLP Rate')"
                          @keypress="onlyNum"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="t('Compostable Qty(MT)')"
                    label-for="vi-compostable_qty"
                    class=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="CompostableQty"
                      vid="vi-compostable_qty"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="vi-compostable_qty"
                          v-model="form.compostable_qty"
                          type="text"
                          maxlength="15"
                          autocomplete="off"
                          :class="errors[0] ? 'border-red' : ''"
                          :placeholder="t('Compostable Qty')"
                          @keypress="onlyNum"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="t('Compostable Rate(MT)')"
                    label-for="vi-compostable_rate"
                    class=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="CompostableRate"
                      vid="vi-compostable_rate"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="vi-compostable_rate"
                          v-model="form.compostable_rate"
                          type="text"
                          maxlength="15"
                          autocomplete="off"
                          :class="errors[0] ? 'border-red' : ''"
                          :placeholder="t('Compostable Rate')"
                          @keypress="onlyNum"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </fieldset>
          </template>
          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
              >
                {{ t('Submit') }}
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="remarkModalCancel"
              >
                {{ t('Cancel') }}
              </b-button>
            </b-col>
          </b-row></b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import constants from '@/constants'
import { hideLoader, showLoader, onlyNumber } from '@/utils/common'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    vSelect,
  },
  props: ['activity_ids', 'date_limit', 'remarkmodaltitle'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      date_error: false,
      form: {
        activity_for_id: this.activity_ids.activity_for_id ? this.activity_ids.activity_for_id : '',
        activity_for: this.activity_ids.activity_for ? this.activity_ids.activity_for : '',
        lead_id: this.activity_ids.lead_id ? this.activity_ids.lead_id : '',
        vendor_id: this.activity_ids.vendor_id ? this.activity_ids.vendor_id : '',
        opportunity_id: this.activity_ids.opportunity_id ? this.activity_ids.opportunity_id : '',
        vendor_unit_id: this.activity_ids.vendor_unit_id ? this.activity_ids.vendor_unit_id : '',
        status: '',
        file_count: '',
        remark: '',
        document: [],
        unit_id: this.activity_ids.unit_id ? this.activity_ids.unit_id : '',
        activity_id: '',
        subject: '',
        reason: '',
        purpose: '',
        discussion: '',
        outcome: '',
        start_date_time: '',
        po_start_date: '',
        po_end_date: '',
        po_amount: '',
        type: '',
        amount: '',
        contact: '',
        attachment: [],
        rigid_qty: '',
        rigid_rate: '',
        flexible_qty: '',
        flexible_rate: '',
        MLP_qty: '',
        MLP_rate: '',
        compostable_qty: '',
        compostable_rate: '',
        pwm_rules: '',
      },
      attechmentReqStages: constants.ATTACHMENT_REQ_STAGES,
      modalTitleExt: '',
      attechmentReq: false,
      loseReason: false,
      Revenue_Onboarded: false,
      addRemarkAPI: '',
      subject: [],
      reasons: [],
      getContactListDispatchPath: '',
      typeList: [
        { id: 1, name: 'Yearly' },
        { id: 2, name: 'Half Yearly' },
        { id: 3, name: 'Monthly' },
        { id: 4, name: 'Quarterly' },
        { id: 5, name: 'After credit transfer' },
        { id: 6, name: 'After allocation' },
      ],
      PWM_Rules: [],
    }
  },
  watch: {
  },
  mounted() {
    this.PWM_Rules = constants.PWM_Rules
    store.dispatch('activityStore/getSubjectList').then(response => {
      this.subject = response.data.data
    })
    store.dispatch('activityStore/lostReasonList').then(response => {
      this.reasons = response.data.data
    })
    this.$root.$on('bv::modal::hidden', (a, componentId) => {
      if (componentId === 'changeStatusModal') {
        this.form = {
          activity_for_id: this.activity_ids.activity_for_id ? this.activity_ids.activity_for_id : '',
          activity_for: this.activity_ids.activity_for ? this.activity_ids.activity_for : '',
          lead_id: this.activity_ids.lead_id ? this.activity_ids.lead_id : '',
          vendor_id: this.activity_ids.vendor_id ? this.activity_ids.vendor_id : '',
          opportunity_id: this.activity_ids.opportunity_id ? this.activity_ids.opportunity_id : '',
          vendor_unit_id: this.activity_ids.vendor_unit_id ? this.activity_ids.vendor_unit_id : '',
          status: '',
          file_count: '',
          remark: '',
          document: [],
          unit_id: this.activity_ids.unit_id ? this.activity_ids.unit_id : '',
          activity_id: '',
          subject: '',
          reason: '',
          purpose: '',
          discussion: '',
          outcome: '',
          start_date_time: '',
          po_start_date: '',
          po_end_date: '',
          po_amount: '',
          type: '',
          amount: '',
          contact: '',
          attachment: [],
          rigid_qty: '',
          rigid_rate: '',
          flexible_qty: '',
          flexible_rate: '',
          MLP_qty: '',
          MLP_rate: '',
          compostable_qty: '',
          compostable_rate: '',
          pwm_rules: '',
        }
      }
    })
    this.$root.$on('getstatusid', (a, b) => {
      this.form.status = a
      this.modalTitleExt = b
      if (a === constants.LOSE_CUSTOMER_ID) {
        this.loseReason = true
      } else {
        this.loseReason = false
      }
      if (a === constants.REVENUE_ON_BOARDED) {
        this.Revenue_Onboarded = true
      } else {
        this.Revenue_Onboarded = false
      }
      if (this.attechmentReqStages.includes(a)) {
        this.attechmentReq = true
      } else {
        this.attechmentReq = false
      }
    })
    if (this.form.activity_for === constants.LEAD) {
      this.getContactListDispatchPath = 'ManageLeads/getLeadContactData'
    } else if (this.form.activity_for === constants.VENDOR) {
      this.getContactListDispatchPath = 'vendorStore/getVendorContactData'
    } else if (this.form.activity_for === constants.OPPORTNUITY) {
      this.getContactListDispatchPath = 'vendorStore/getOpportunityContactData'
    }
    this.getUsersList()
    // eslint-disable-next-line eqeqeq
    if (this.activity_ids.activity_for == constants.LEAD) {
      this.addRemarkAPI = 'opportunityStore/changeStatusLead'
    // eslint-disable-next-line eqeqeq
    } else if (this.activity_ids.activity_for == constants.OPPORTNUITY) {
      this.addRemarkAPI = 'opportunityStore/changeStatusOpportunity'
    }
  },
  methods: {
    changeDateTime() {
      if (this.form.start_date_time) {
        this.date_error = false
      }
    },
    getUsersList() {
      store.dispatch(this.getContactListDispatchPath, this.form).then(response => {
        this.allContactList = response.data.data.data
      })
    },
    addRemarkSubmit() {
      // eslint-disable-next-line prefer-const
      let formData = new FormData()
      this.form.file_count = this.form.attachment.length
      Object.entries(this.form).forEach(
        ([key, value]) => {
          // eslint-disable-next-line eqeqeq
          if (key == 'attachment') {
            formData.append('', '')
          } else {
            formData.append(key, value)
          }
        },
      )
      Object.entries(this.form.attachment).forEach(
        ([key, value]) => {
          formData.append(`file${key}`, value)
        },
      )
      this.$refs.changeStatusModal.validate().then(success => {
        if (success) {
          if (!this.form.start_date_time) {
            this.date_error = true
            return
          }
          this.addRemark(formData)
        }
      })
    },
    async addRemark(formData) {
      showLoader()
      await store.dispatch(this.addRemarkAPI, formData).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.$bvModal.hide('changeStatusModal')
          this.$emit('loadAllRemarks')
          this.$root.$emit('refreshLeadData')
          this.$root.$emit('refreshActivity')
          this.$root.$emit('loadAllDocuments')
          hideLoader()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: '',
            },
          })
          this.$refs.changeStatusModal.reset()
        } else {
          hideLoader()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: e.msg,
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    remarkModalCancel() {
      this.$bvModal.hide('changeStatusModal')
    },
    onlyNum(a) {
      return onlyNumber(a)
    },
  },
}
</script>

<style>
.fieldset-style {
  border: 1px solid #717070;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
}
</style>
