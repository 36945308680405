<template>

  <div>
    <!-- v-if="opportunityData.is_vendor" -->

    <div
      v-if="!opportunityData.is_vendor"
      id="smartwizard"
      class="sw sw-justified sw-theme-arrows"
    >
      <ul class="nav flex-nowrap overflow-x-scroll">
        <li
          v-for="leadstatus in opportunityStepStatus"
          :key="leadstatus.id"
          class="nav-item h-70 statusStep"
        >
          <a
            v-if="leadstatus.para_id < opportunityStepStatus[opportunityStepStatus.length - 1].para_id"
            class="nav-link d-flex justify-content-center align-items-center inactive"
            href="javascript:void(0)"
            :class="opportunityData.OpportunityStatus && opportunityData.OpportunityStatus.includes(leadstatus.para_id) ? 'done' : ''"
          >
            <strong>{{ leadstatus.para_value }}</strong>
            <!-- :class="opportunityData.is_close || opportunityData.opporunity_status == opportunityStepStatus[opportunityStepStatus.length - 1].para_id ? 'danger' : leadstatus.para_id <= opportunityData.opporunity_status ? 'done' : ''" -->
            <!-- <br>{{ leadstatus.para_desc }} -->
          </a>
          <a
            v-else
            class="nav-link d-flex justify-content-center align-items-center inactive"
            href="javascript:void(0)"
            :class="opportunityData.OpportunityStatus && opportunityData.OpportunityStatus.includes(leadstatus.para_id) || opportunityData.is_close ? 'danger' : ''"
          >
            <strong>{{ leadstatus.para_value }}</strong>
          </a>
        </li>
      </ul>
    </div>
    <b-card
      no-body
      class="mb-1"
    >
      <template #header>
        <div class="d-flex w-100 justify-content-between">
          <div class="d-flex">
            <h3 class="mb-0">
              {{ opportunityData.vendor_name }} - {{ opportunityData.vendor_code }}
            </h3>
            <span>
              <b-badge
                pill
                class="ml-2 px-1"
                variant="success"
              >{{ opportunityData.lead_service_name }}</b-badge>
            </span>
            <span
              v-if="opportunityData.status == 'A'"
              class="ml-2"
            > <b-badge
              pill
              variant="success"
            >{{ t('Active') }}</b-badge></span>
            <span
              v-if="opportunityData.status == 'I'"
              class="ml-2"
            > <b-badge
              pill
              variant="Danger"
            >In-Active</b-badge></span>
          </div>
          <div>
            <b-dropdown
              v-if="vendorBtns"
              :text="t(selectUnit)"
              variant="primary"
              class="pl-1 mxh-250"
            >
              <b-dropdown-item
                v-for="vulist in vendorUnitList"
                :key="vulist.vendor_unit_id"
                @click="filterUnitid(vulist.vendor_unit_id, vulist.unit_title)"
              >
                {{ vulist.unit_title }}
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              v-if="!opportunityData.is_close"
              variant="outline-danger"
              class="ml-1 "
              @click="closeLead"
            >
              {{ t('Close Lead') }}
            </b-button>
            <b-dropdown
              v-if="!opportunityData.is_close"
              :text="t('Change Status')"
              variant="primary"
              class="pl-1"
              right
            >
              <b-dropdown-item
                v-for="(status, i) in opportunityStepStatus"
                :key="status.para_id"
                class="position-relative"
                :class="status.para_id <= opportunityData.opporunity_status ? 'bg-success pointer-event-none' : constants.ACTIVE_OPPORTUNITY_STATUS.includes(status.para_id) ? '' : opportunityData.OpportunityStatus.includes(constants.OPPORTNUITY_STATUS_INFO_RECIEVED) ? '' : i == opportunityStepStatus.length - 1 ? '' : status.para_sort_order > opportunityData.opporunity_status_order+1 ? 'pointer-event-none' : ''"
                @click="changeStatus(status.para_id, status.para_value)"
              >
                <span :class="status.para_id <= opportunityData.opporunity_status ? 'text-white' : ''">{{ status.para_value }}</span>
                <feather-icon
                  :icon="status.para_id <= opportunityData.opporunity_status ? 'CheckCircleIcon' : ''"
                  class="position-absolute text-white right-10"
                />

                <!-- <b-dropdown-item
                v-if="!addUnitData.vendor_id"
                @click="$router.push(`/lead/${createdParams.lead_id}/opportunity-list`)"
              >
                Opportunity List
              </b-dropdown-item> -->
              </b-dropdown-item></b-dropdown>
          </div>
        </div>
      </template>

      <!-- <b-card-body>
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CopyIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Type') }}</span>
            </th>
            <td class="pb-50">
              {{ opportunityData.vendor_type_name }}
            </td>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Customer Type') }}</span>
            </th>
            <td class="pb-50">
              {{ opportunityData.customer_type }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Created By') }}</span>
            </th>
            <td class="pb-50">
              {{ opportunityData.created_by_name }}
            </td>
            <th class="pb-50">
              <feather-icon
                icon="LinkIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Website') }}</span>
            </th>
            <td class="pb-50">
              <a
                :href="opportunityData.website"
                target="_blank"
              >{{ opportunityData.website }}</a>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Landline No.') }}</span>
            </th>
            <td class="pb-50">
              {{ opportunityData.landline }} <span v-if="opportunityData.extention">{{ `- ${opportunityData.extention}` }}</span>
            </td>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Created Date') }}</span>
            </th>
            <td class="pb-50">
              {{ opportunityData.created_date }}
            </td>
          </tr>
        </table>
      </b-card-body> -->

    </b-card>
    <quick-links
      v-if="Object.keys(opportunityData).length > 0"
      :vendor_id="opportunityData.enc_vendor_id"
    />
    <b-row>
      <b-col xl="8">
        <b-card>
          <b-tabs
            class="vendor_details"
            content-class=""
            justified
            pills
            :vertical="verticalTab"
          >
            <b-tab
              active
              :title="t('Contacts')"
            >
              <contacts
                :is_edit="opportunityData.is_edit ? opportunityData.is_edit : 0"
                :opportunity_id="$route.params.id"
                :lead_id="opportunityData.lead_id"
                :activity_ids="activity_ids"
              />
            </b-tab>
            <!-- <b-tab
            title="Units"
          > <units
            v-if="Object.keys(opportunityData).length > 0"
            :units="opportunityData.VendorAddress"
          /></b-tab> -->
            <b-tab :title="t('Documents')">
              <documents
                v-if="Object.keys(opportunityData).length > 0"
                :activity_ids="activity_ids"
                :is_edit="opportunityData.is_edit ? opportunityData.is_edit : 0"
              />
            </b-tab>
            <b-tab
              :title="t('Information Received')"
              @click="loadInfoReceive"
            >
              <ViewTemplate
                v-if="Object.keys(opportunityData).length > 0"
                ref="childComponentRef"
              />
            </b-tab>
            <b-tab
              v-if="opportunityData.is_proposal_tab"
              :title="t('Proposal Preparation')"
              @click="loadProposal"
            >
              <ViewTemplate
                v-if="Object.keys(opportunityData).length > 0"
                ref="childComponentRefP"
              />
            </b-tab>
            <b-tab
              v-if="opportunityData.is_state_qty"
              :title="t('StateWise QTY')"
            >
              <StateWiseQuantity />
            </b-tab>
            <b-tab
              v-if="opportunityData.opporunity_status === constants.REVENUE_ON_BOARDED"
              :title="t('Revenue Detail')"
            >
              <Revenue :revenueData="revenueData"
              />
            </b-tab>
            <b-tab :title="t('Reminder')">
              <reminders
                v-if="Object.keys(opportunityData).length > 0"
                :activity_ids="activity_ids"
                :is_edit="opportunityData.is_edit ? opportunityData.is_edit : 0"
              />
            </b-tab>
            <b-tab :title="t('Remarks')">
              <remarks
                v-if="Object.keys(opportunityData).length > 0"
                :activity_ids="activity_ids"
                :is_edit="opportunityData.is_edit ? opportunityData.is_edit : 0"
                :remarkmodaltitle="remarkmodaltitle"
                :close_remark="opportunityData.close_remark"
                :date_limit="opportunityData.activity_date_limit "
                :is_edit_opportunity="1"
              />
            </b-tab>
            <!-- <b-tab title="Activities">
              <activities :id="vendor_id" />
            </b-tab> -->
          </b-tabs>
        </b-card>
      </b-col>
      <b-col
        xl="4"
        class="pl-md-0 h-100"
      >
        <b-card
          id="activity"
          class="activity-h-scroll"
        >
          <activity
            v-if="Object.keys(opportunityData).length > 0 && opportunityData.is_edit"
            :contacts="opportunityData.VendorContact"
            :data_ids="activity_ids"
            :is_edit="opportunityData.is_edit ? opportunityData.is_edit : 0"
            :date_limit="opportunityData.activity_date_limit"
            :editactivitydata="editActivityData"
          />
          <activities
            :dataids="activity_ids"
            :is_edit="opportunityData.is_edit ? opportunityData.is_edit : 0"
            @getActivityById="getActivityById"
          />

        </b-card>
      </b-col>
    </b-row>
    <close-lead
      :data_ids="activity_ids"
    />
    <change-status
      :activity_ids="activity_ids"
      :date_limit="opportunityData.activity_date_limit"
      :remarkmodaltitle="remarkmodaltitle"
    />
  </div>
</template>

<script>
import {
  BCard, /* BCardBody, */ BTabs, BTab, BDropdown, BDropdownItem, BBadge, BRow, BCol, VBToggle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils } from '@core/libs/i18n'
import checkPermissions from '@/utils/checkPermissons'
import Contacts from '../Vendor/VendorTabs/Contacts.vue'
// import Units from './VendorTabs/Units.vue'
import Reminders from '../Vendor/VendorTabs/Reminders.vue'
import Activity from '../Vendor/Activity.vue'
import Remarks from '../Vendor/VendorTabs/Remarks.vue'
import Revenue from '../Vendor/VendorTabs/Revenue.vue'
import CloseLead from '../Vendor/CloseLead.vue'
import QuickLinks from '../Dashboard/QuickLinks.vue'
import Documents from '../Vendor/VendorTabs/Documents.vue'
import Activities from '../Vendor/VendorTabs/Activities.vue'
import { showLoader, hideLoader } from '@/utils/common'
import store from '@/store'
import constants from '@/constants'
import ChangeStatus from '../Vendor/VendorTabs/ChangeStatus.vue'
import ViewTemplate from '../FormQuestions/ViewTemplate.vue'
import StateWiseQuantity from '../FormQuestions/StateWiseQuantity.vue'

export default {
  components: {
    Activity,
    BCard,
    // BCardBody,
    BTabs,
    BRow,
    BCol,
    BTab,
    Contacts,
    Reminders,
    BBadge,
    Remarks,
    Revenue,
    Documents,
    Activities,
    BDropdown,
    BDropdownItem,
    CloseLead,
    QuickLinks,
    ChangeStatus,
    ViewTemplate,
    StateWiseQuantity,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      opportunityStepStatus: [],
      constants,
      editActivityData: [],
      remarkmodaltitle: constants.ADD_REMARK_TEXT,
      activity_ids: {
        activity_for_id: this.$route.params.id,
        activity_for: constants.OPPORTNUITY,
        opportunity_id: this.$route.params.id,
        lead_id: '',
        vendor_id: '',
        unit_id: '',
      },
      addUnitData: {
        vendor_id: '',
      },
      createdParams: {
        vendor_id: '',
        lead_id: '',
        opportunity_id: this.$route.params.id,
      },
      opportunityData: {},
      vendorBtns: false,
      vendorUnitList: [],
      verticalTab: false,
      selectUnit: 'Select Unit',
      revenueData: {},
    }
  },
  async mounted() {
    this.getRevenueData(this.$route.params.id)
    this.createdParams.opportunity_id = this.$route.params.id
    this.activity_ids.opportunity_id = this.$route.params.id
    this.activity_ids.unit_id = ''
    this.activity_ids.vendor_id = ''
    showLoader()
    await this.getOpportunityById()
    this.getLeadStatus()
    hideLoader()
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) {
        this.verticalTab = true
      } else {
        this.verticalTab = false
      }
    })
    if (window.innerWidth <= 768) {
      this.verticalTab = true
    } else {
      this.verticalTab = false
    }
    this.$root.$on('refreshLeadData', () => {
      this.getOpportunityById()
    })
  },
  methods: {
    loadInfoReceive() {
      this.$root.$emit('loadInfoReceiveD', this.opportunityData)
      this.$refs.childComponentRef.loadInfoReceiveData()
    },
    loadProposal() {
      this.$root.$emit('loadProposalD', this.opportunityData)
      this.$refs.childComponentRefP.loadProposalData()
    },
    closeLead() {
      this.$bvModal.show('closeLeadModal')
    },
    checkPermission(val) {
      return checkPermissions(val)
    },
    async refreshLeadData() {
      showLoader()
      await this.getLeadById()
      this.getLeadStatus()
      hideLoader()
    },
    getLeadStatus() {
      store.dispatch('opportunityStore/opportunityStepStatus').then(response => {
        this.opportunityStepStatus = response.data.data
      })
    },
    async getActivityById(id) {
      showLoader()
      await store.dispatch('activityStore/getActivityById', { activity_id: id }).then(response => {
        this.editActivityData = response.data.data
        document.getElementById('activity').scrollTop = 0
      })
      hideLoader()
    },
    async getRevenueData(id) {
      showLoader()
      await store.dispatch('opportunityStore/getRevenueDataById', { opportunity_id: id }).then(response => {
        this.revenueData = response.data.data
      })
      hideLoader()
    },
    getOpportunityById() {
      store.dispatch('opportunityStore/getOpportunityDataById', this.createdParams).then(response => {
        this.opportunityData = response.data.data
        this.activity_ids.lead_id = this.opportunityData.lead_id
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something Went Wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    getVendorUnitList() {
      store.dispatch('vendorStore/getVendorUnitList', this.createdParams).then(response => {
        this.vendorUnitList = response.data.data.data
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something Went Wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
      })
    },
    filterUnitid(unitId, unitTitle) {
      this.selectUnit = unitTitle
      this.activity_ids.activity_for = constants.VENDOR_UNIT
      this.activity_ids.unit_id = unitId
      showLoader()
      store.dispatch('vendorStore/getVendorById', { vendor_id: this.createdParams.vendor_id, vendor_unit_id: unitId }).then(response => {
        this.opportunityData = response.data.data
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something Went Wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })

      hideLoader()
    },
    async changeStatus(statusid, statusTitle) {
      showLoader()
      this.remarkmodaltitle = constants.CHANGE_STATUS_ACTIVITY_MODAL_TITLE
      this.$bvModal.show('changeStatusModal')
      this.$root.$emit('getstatusid', statusid, statusTitle)
      hideLoader()
    },
    addUnit() {
      this.$bvModal.show('addUnit')
    },
    loadUnits() {
      this.getVendorUnitList()
    },
    unitList(vid) {
      this.$router.push(`/vendor/${vid}/unit-list`)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped lang="css">
@import url('https://cdn.jsdelivr.net/npm/smartwizard@5/dist/css/smart_wizard_all.min.css');

</style>
<style>
.tabs ul.nav li.nav-item a.nav-link {
  white-space: nowrap;
}
.mxh-250 ul.dropdown-menu{
  max-height: 250px;
  overflow-y: scroll;
}
.card {
  margin-bottom: 1rem !important;
}
.mxh-250 ul.dropdown-menu::-webkit-scrollbar{
  display: none;
}
.activity-h-scroll{
  /* height: 455px; */
  min-height: 455px;
  height: calc(100vh - 368px);
  overflow: auto;
}
.activity-h-scroll::-webkit-scrollbar{
    display: none;
}
 .nav-pills.nav-justified .nav-link {
    border: 1px solid #7367f0;
}
 .nav-pills.nav-justified .nav-item {
    display: table;
    position: relative;
}
.tabs.vendor_details .tab-pane.active:not(.tab-card-fixed-height .tabs *):not(.tab-card-fixed-noBorder .tabs *){
    border: 2px solid rgb(115, 103, 240);
    padding: 1rem;
    border-radius: 10px;
    overflow-y: auto;
    height: 100%;
}
.tabs.vendor_details .nav-link.active:not(.tab-card-fixed-height .tabs *){
  position: relative;
}
.tabs.vendor_details .nav-link.active:after:not(.tab-card-fixed-height .tabs *) {
    content: "";
    position: absolute;
    left: auto;
    z-index: 11;
    display: block;
    width: 12px;
    height: 12px;
    background: white;
    border-top: 2px solid #7367f0;
    border-left: 2px solid #7367f0;
    top: 42px;
    right: 50%;
    transform: rotate(45deg) translateX(50%);
}
.h-70{
  height: 70px;
}
.overflow-x-scroll{
  overflow-x: scroll !important;
}
.overflow-x-scroll::-webkit-scrollbar{
  display: none;
}
.statusStep a::after {
    top: 0 !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    border-top: 35px solid transparent !important;
    border-bottom: 35px solid transparent !important;
}
.statusStep a::before {
    top: 0 !important;
    margin-top: 0 !important;
    margin-left: 1px !important;
    border-top: 35px solid transparent !important;
    border-bottom: 35px solid transparent !important;
    border-left: 30px solid #ddd;
}
.tab-pane.active {
  position: relative;
}
@media (max-width: 768px) {
  .tabs.vendor_details .nav-link.active:after:not(.tab-card-fixed-height .tabs *) {
    left: auto !important;
    top: 50% !important;
    right: -32px !important;
    transform: rotate(315deg) translateX(50%) !important;
}
}
</style>
