<template>
  <b-modal id="importStateWiseQuantityModal" :title="modalTitle" size="lg" hide-footer>
    <b-card>
      <b-form enctype="multipart/form-data" autocomplete="off" @submit.prevent="submitData">
        <b-row>
          <b-col cols="12" class="p-0">
            <b-form-group :label="t('Upload Excel File')" label-for="vi-import-excel" class="required">
              <validation-provider #default="{ errors }" name="Business Card" vid="vi-import-excel" rules="">
                <b-input-group class="input-group-merge">
                  <b-form-file id="vi-import-excel" ref="files" v-model="form.file"
                    :class="preview_message ? 'border-red-file' : ''" :browse-text="t('Browse')" name="files" type="file"
                    accept=".xlsx" :placeholder="t('Select Excel File')" @change="getprofile($event)" />
                </b-input-group>
                <div v-if="import_excel_preview" class="mt-2">
                  <a v-if="preview_message" target="blank" href="#" class="text-danger"> {{ file_error }}</a>
                  <a v-else target="blank" :href="import_excel_preview">{{ 'Preview' }}</a>
                </div>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="text-center py-1">
            <b-button type="submit" variant="primary" class="mr-2">
              {{ t('Submit') }}
            </b-button>
            <b-button type="button" variant="outline-secondary" @click="editModalCancel">
              {{ t('Cancel') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-modal>
</template>

<script>
import 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { useUtils } from '@core/libs/i18n'
import * as XLSX from 'xlsx'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { hideLoader, showLoader } from '@/utils/common'
import store from '@/store'
import constants from '@/constants'

export default {
  components: {
    ValidationProvider,
    // ValidationObserver,
  },
  props: ['importExcelData'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      importExcel: false,
      modalTitle: 'Import Statewise Quantity',
      import_excel_preview: '',
      preview_message: false,
      file_error: '',
      opportunity_id: this.$route.params.id,
      form: {
        file: [],
        file_count: 0,
      },
      data: {
        opportunity_id: this.$route.params.id,
        importData: [],
      },
    }
  },
  watch: {
    importExcelData: {
      handler() {
        this.preview_message = null
        this.import_excel_preview = null
        this.importExcel = true
      },
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', componentId => {
      if (componentId === 'importStateWiseQuantityModal') {
        this.importExcel = false
      }
    })
  },
  methods: {
    submitData() {
      if (!this.preview_message) {
        // eslint-disable-next-line prefer-const
        if (this.form.file) {
          const reader = new FileReader()
          reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result
            const wb = XLSX.read(bstr, { type: 'binary' })
            /* Get first worksheet */
            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
            // eslint-disable-next-line eqeqeq
            if (JSON.stringify(data[0]) == JSON.stringify(constants.importExcelProperty)) {
              showLoader()
              this.data.importData = data
              store.dispatch('questionsStore/importExcelData', this.data).then(response => {
                // eslint-disable-next-line eqeqeq
                if (response.data.code == constants.SUCCESS) {
                  showLoader()
                  this.$bvModal.hide('importStateWiseQuantityModal')
                  this.$root.$emit('refreshStateQunatity')
                  hideLoader()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: response.data.msg,
                      icon: 'Success',
                      variant: 'success',
                      text: '',
                    },
                  })
                  // eslint-disable-next-line eqeqeq
                } else if (response.data.code == constants.ERROR_500) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: response.data.msg,
                      icon: 'Danger',
                      variant: 'danger',
                      text: '',
                    },
                  })
                }
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Invalid Format',
                  icon: 'Danger',
                  variant: 'danger',
                  text: '',
                },
              })
            }
          }
          reader.readAsBinaryString(this.form.file)
        }
      }
    },
    async getStateQuantityCategory() {
      await store.dispatch('opportunityStore/stateQuantityCategory', '').then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.categories = response.data.data
          this.form.qty_data.forEach(f => {
            this.categories.forEach(cat => {
              f.category.push({ id: cat.para_id, qty: '' })
            })
          })
          this.loaded = true
        }
      })
    },
    editModalCancel() {
      this.$bvModal.hide('importStateWiseQuantityModal')
    },
    getprofile(event) {
      if (event.target.files.length > 0) {
        // eslint-disable-next-line eqeqeq
        if (event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          this.preview_message = true
          this.file_error = 'Invalid File Format Only xlsx files are allowed'
        } else {
          this.preview_message = false
        }
        this.form.file_count = 1
        this.import_excel_preview = URL.createObjectURL(event.target.files[0])
      }
    },
  },
}
</script>

<style></style>
